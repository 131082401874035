/**
 * Created by Bugatti on 06/10/2017.
 */
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, map, publishReplay, refCount } from "rxjs/operators";
import { BaseService } from "../base.service";
import { Auth } from 'src/app/class/auth';


@Injectable({
  providedIn:'root'
})
export class ContractorShopService extends BaseService {


  private _coMyServices: Observable<any> = null;
  private _coServices: Observable<any> = null;
  private _coCalendarServices: Observable<any> = null;
  private _coReservation: Observable<any> = null;
  private _coSpecific: Observable<any> = null;
  private _coBarberShop: Observable<any> = null;
  private _coRib: Observable<any> = null;
  private _subCategory: Observable<any> = null;
  private _coCategory: Observable<any> = null;
  private _coServicesFromCategory: Observable<any> = null;
  private _coMyServicesById: Observable<any> = null;
  private _coMoney: Observable<any> = null;

  /**
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Reload data even if it is cached
   * Load item from cache if it's in cache or load from origin observable
   */
  clearMyServicesCache() {
    this._coMyServices = null;
  }

  clearServicesCache() {
    this._coServices = null;
  }

  clearCategoriesCache() {
    this._coCategory = null;
  }

  clearMoneyCache() {
    this._coMoney = null;
  }

  clearServiceFromCategoriesCache() {
    this._coServicesFromCategory = null;
  }

  clearBarberShopServicesCache() {
    this._coBarberShop = null;
  }

  clearReservationCache() {
    this._coReservation = null;
  }

  clearCalendarCache() {
    this._coCalendarServices = null;
  }

  clearSpecificCache() {
    this._coSpecific = null;
  }

  clearSubCategoryCache() {
    this._subCategory = null;
  }

  /**
   *
   * @returns {string}
   */
  getEntityName() {
    return 'getMyServices';
  }

  /**
   * Retourne la liste des services d'un prestataire connecté via le token
   * @returns {Observable<R|T>}
   */
  getMyService(token, field): Observable<Auth> {
    let headers = { 'Jwt': token };

    this._coMyServices = this.http.get(this.baseUrl + `getMyServices${field}`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount() // used to keep the observable alive for as long as there are subscribers.
    )
    return this._coMyServices
  }

  /**
   * 
   * @returns {Observable<R|T>}
   */
  getNoteStatus(token): Observable<any> {
    let headers = { 'Jwt': token };
    return this.http.get(`${this.baseUrl}getNoteStatus`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError)
    )
  }



  /**
  * Retourne la liste des services d'un prestataire connecté via le token
  * @returns {Observable<R|T>}
  */
  getMyServiceById(token, id): Observable<Auth> {
    let headers = { 'Jwt': token };
    return this.http.get(`${this.baseUrl}getMyServices?id_prestation=${id}`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError)
    )
  }


  commentaryCustomer(param: any, token: any): Observable<Auth> {
    let headers = { 'Jwt': token };
    return this.http.get(`${this.baseUrl}GetmyCalendar${param}`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError)
    )
  }

  /**
   * Return all prestations for a contract by id services (You can call this method whitout token)
   * @param contractor
   * @returns {Observable<Auth>}
   */
  getPrestationServicesById(id): Observable<Auth> {
    console.log('fetching all prestation contractor by id...')
    return this.http.get(`${this.baseUrl}getPrestaService?id_prestation=${id}`).pipe(
      map(res => res),
      catchError(this.handleError)
    )
  }



  /**
   * Return all prestations for a contractor by id services (You can call this method whitout token)
   * @param contractor
   * @returns {Observable<Auth>}
   */
  getPrestationServicesBycategoryId(categoryId): Observable<Auth> {
    console.log('fetching all prestation contractor by id...')
    return this.http.get(`${this.baseUrl}getPrestaService?id_scategorie=${categoryId}`).pipe(
      map(res => res),
      catchError(this.handleError)
    )
  }

  /**
    * Return all prestations for a contractor by id services (You can call this method whitout token)
    * @param contractor
    * @returns {Observable<Auth>}
    */
  getPrestationBycategoryId(serviceId,token): Observable<Auth> {
    console.log('fetching all prestation contractor by id...')

    if(token){
      let headers = { 'Jwt': token };
    
    return this.http.get(`${this.baseUrl}getPrestation?id_service=${serviceId}`,{ headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError)
    )
    }
    
    return this.http.get(`${this.baseUrl}getPrestation?id_service=${serviceId}`).pipe(
      map(res => res),
      catchError(this.handleError)
    )
  }





  /**
   * Retrieve category services via le token
   * @param unique
   * @param limit
   * @returns {Observable<Auth>}
   */
  getShopService(unique, limit): Observable<Auth> {
    let uniqueFilter = `?unique=${unique}&limit=${limit}`;
      return this._coServices = this.http.get(`${this.baseUrl}getServices${uniqueFilter}`).pipe(
        map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
    
  }

    /**
     * @param categoryId
     * @returns {Observable<Auth>}
     */
    getShopServiceFromCategory(categoryId): Observable <Auth> {

      return this.http.get(`${this.baseUrl}getServices?id_scategorie=${categoryId}`).pipe(
        map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount()) // used to keep the observable alive for as long as there are subscribers.


    }

    /**
     * Retrieve category services via le token
     * @param unique
     * @param limit
     * @returns {Observable<Auth>}
     */
    getCategoriesService(): Observable < Auth > {
      return this.http.get(`${this.baseUrl}getCategorie`).pipe(
        map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount()) // used to keep the observable alive for as long as there are subscribers.

    }

    /**
     *
     * @param categoryId
     * @returns {Observable<Auth>}
     */
    getSubCategoryServices(categoryId): Observable <Auth> {

      return this.http.get(`${this.baseUrl}getScategorie?id_categorie=${categoryId}`)
      .pipe(
        map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount()) // used to keep the observable alive for as long as there are subscribers.

    }

    /**
     * Retrieve contractor calendar services via le token
     * @returns {Observable<R|T>}
     */
    getContractorCalendarService(token: any): Observable<Auth> {
      let headers = { 'Jwt': token };
      if(!this._coCalendarServices) {
      this._coCalendarServices = this.http.get(`${this.baseUrl}GetmyCalendar`, { headers: headers }).pipe(
        map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount()) // used to keep the observable alive for as long as there are subscribers.

    }
    return this._coCalendarServices
  }

  /**
   * Retrieve reservation from contractor via le token
   * @param token
   * @returns {Observable<Auth>}
   */
  getReservation(token: any, action, limit): Observable<Auth> {
    let headers = { 'Jwt': token };
    let param = "limit=" + limit + "&&history=" + action;
    this._coReservation = this.http.get(`${this.baseUrl}getReservation?${param}`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount()) // used to keep the observable alive for as long as there are subscribers.

    return this._coReservation
  }


  /**
     * 
     * @param token
     * @returns {Observable<Auth>}
     */
  getReservationById(token: any, id): Observable<Auth> {
    let headers = { 'Jwt': token };
    //let param =`id_reservation=${id}`;
    this._coReservation = this.http.get(`${this.baseUrl}getReservation?${id}`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount()) // used to keep the observable alive for as long as there are subscribers.

    return this._coReservation
  }


  /**
   * 
   * @param token
   * @returns {Observable<Auth>}
   */
  getReservationByIdClient(token: any, param): Observable<Auth> {
    let headers = { 'Jwt': token };
    this._coReservation = this.http.get(`${this.baseUrl}getReservation?${param}`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount()) // used to keep the observable alive for as long as there are subscribers.

    return this._coReservation
  }


  /**
   * 
   * @param token
   * @returns {Observable<any>}
   */
  getDashboard(token: any, param): Observable<any> {
    let headers = { 'Jwt': token };
    this._coReservation = this.http.get(`${this.baseUrl}getDashboard?${param}`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount()); // used to keep the observable alive for as long as there are subscribers.

    return this._coReservation
  }




  /**
   * Retrieve contractor rib
   * @param token
   * @returns {Observable<Auth>}
   */
  getRibService(token: any): Observable<Auth> {
    let headers = { 'Jwt': token };
    return this.http.get(`${this.baseUrl}getRib`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount()) // used to keep the observable alive for as long as there are subscribers.

  }

  /**
   * @description
   * @param token
   * @returns {Observable<Auth>}
   */
  getMoneyService(token: any): Observable<Auth> {
    let headers = { 'Jwt': token };
    return this.http.get(`${this.baseUrl}getMoneybank`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount()) // used to keep the observable alive for as long as there are subscribers.

  }

  /**
   * Update Contractor RIB via API
   * @param rib
   * @param token
   * @returns {Observable<R|T>}
   */
  postRibService(rib: Object, token): Observable<Auth> {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };


    return this.http.post(`${this.baseUrl}setRib`, this.serialize(rib), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      );
  }

  /**
   * Subscribe on service via API
   * @param service
   * @param token
   * @returns {Observable<R|T>}
   */
  postSubscribeService(service: Object, token): Observable<Auth> {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.baseUrl}subscribeServices`, this.serialize(service), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
  }

  /**
   * UnSubscribe from service via API
   * @param service
   * @param token
   * @returns {Observable<R|T>}
   */
  unSubscribeService(service: Object, token): Observable<Auth> {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.baseUrl}unsubscribeServices`, this.serialize(service), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
  }

  /**
   * Update product service via API
   * @param service
   * @param token
   * @returns {Observable<R|T>}
   */
  updateProductService(service: Object, token): Observable<Auth> {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.baseUrl}updatePrestation`, this.serialize(service), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
  }

  /**
   * Update specific service via API
   * @param specific
   * @param token
   * @returns {Observable<R|T>}
   */
  updateSpecificService(specific: Object, token): Observable<Auth> {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.baseUrl}setCaracteristiquePrestation`, this.serialize(specific), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
  }

  /**
   * @description add new hairdresser via API
   * @param barberShop
   * @param token
   * @returns {Observable<R|T>}
   */
  addBarberShopService(barberShop: Object, token): Observable<Auth> {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.baseUrl}setSalon`, this.serialize(barberShop), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
  }

  /**
   * @description update new hairdresser via API
   * @param barberShop
   * @param token
   * @returns {Observable<R|T>}
   */
  updateBarberShopService(barberShop: Object, token): Observable<Auth> {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.baseUrl}updateSalon`, this.serialize(barberShop), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
  }


  /**
   * @description remove a hairdresser via API
   * @param barberShop
   * @param token
   * @returns {Observable<R|T>}
   */
  removeBarberShopService(barberShop: Object, token): Observable<Auth> {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.baseUrl}removeSalon`, this.serialize(barberShop), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
  }

  /**
   * @description Retrieve hairdresser for contractor via le token
   * @param barberShop
   * @param token
   * @returns {Observable<Auth>}
   */
  getBarberShopService(barberShop: any, token: any): Observable<Auth> {
    let headers = { 'Jwt': token };
    let fields = `?id_prestataire=${barberShop.id_prestataire}`;
    return this.http.get(`${this.baseUrl}getSalon${fields}`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount()) // used to keep the observable alive for as long as there are subscribers.

    // (@returns Observable)
  }

  /**
   * Retrieve specific via le token
   * @param specific
   * @param token
   * @returns {Observable<Auth>}
   */
  getSpecificService(specific: any, token: any): Observable<Auth> {
    let headers = { 'Jwt': token };
    let fields = `?id_service=${specific.id_service}`;

    return this.http.get(`${this.baseUrl}getCaracteristiqueService${fields}`, { headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount() // used to keep the observable alive for as long as there are subscribers.

    )// used to keep the observable alive for as long as there are subscribers.

    // (@returns Observable)
  }

  /**
   * Accept booking via API
   * @param booking
   * @param token
   * @returns {Observable<R|T>}
   */
  postAcceptOrRefuseBookingService(booking: Object, token): Observable<Auth> {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.baseUrl}answerTheReservation`, this.serialize(booking), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
  }

  /**
   * Valid qrCode via API
   * @param qrCode
   * @param token
   * @returns {Observable<Auth>}
   */
  validQrCodeService(qrCode: any, token): Observable<Auth> {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.baseUrl}validateCode`, this.serialize(qrCode), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
  }

  /**
   * Paid order via API
   * @param order
   * @param token
   * @returns {Observable<R|T>}
   */
  cancelTransactionPaidOrder(order: Object, token): Observable<Auth> {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };
    return this.http.post(`${this.baseUrl}prestataireCancelReservation`, this.serialize(order), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
  }

  /**
   * Retourne l'url de l'api
   * @returns {string}
   */
  protected getEntityUrl() {
    return this.baseUrl + this.getEntityName();
  }

}
