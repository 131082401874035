/**
 * Created by Bugatti on 06/10/2017.
 */
import {Injectable} from '@angular/core';
import {BaseService} from "../base.service";
import {Observable, pipe} from "rxjs";
import {catchError, map, publishReplay, refCount} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import { Auth } from 'src/app/class/auth';


@Injectable({
  providedIn:'root'
})
export class UpdateUserService extends BaseService {

  private _media: Observable<any> = null;

  /**
   *
   * @param http
   */
  constructor(private http: HttpClient) {
    super();
  }

  /**
   *
   * @returns {string}
   */
  getEntityName() {
    return 'updateUserInfos';
  }

  /**
   * Mise à jour des informations d'un utilisateur via API
   * @param data
   * @param token
   * @returns {Observable<R|T>}
   */
  updateUserInfos(data: Object, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(this.getEntityUrl(), this.serialize(data), {headers: headers})
     .pipe( map(res => res),
     catchError(this.handleError),
     publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
     refCount() // used to keep the observable alive for as long as there are subscribers.
 )
  }

  /**
   *
   * @param {Object} data
   * @param token
   * @returns {Observable<Auth>}
   */
  updateEmail(data: Object, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(this.baseUrl + 'updateUsermail', this.serialize(data), {headers: headers})
    .pipe( map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
)
  }


  /**
   * Mise à jour de la photo de profil d'un utilisateur via API
   * @param image
   * @param token
   * @returns {Observable<R|T>}
   */
  uploadPicture(image: any, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}updatePictureProfil`, this.serialize(image), {headers: headers})
    .pipe( map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
)
  }

  /**
   * Reload data even if it is cached
   * Load item from cache if it's in cache or load from origin observable
   */
  clearMediaCache() {
    this._media = null;
  }

  /**
   * Retrieve media services via le token
   * @param media
   * @param token
   * @returns {Observable<Auth>}
   */
  getMediaService(media: any, token: any): Observable<Auth> {
    let headers = {'Jwt': token};
    let fields = `?id_prestation=${media.id_prestation}`;
    if (!this._media) {
      return this.http.get(`${this.baseUrl}getMediasPrestation${fields}`, {headers: headers}).pipe(
       map(res => res),
     catchError(this.handleError),
     publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
     refCount() // used to keep the observable alive for as long as there are subscribers.
 )
    }
    // (@returns Observable)
    return this._media;
  }

  /**
   * Retourne l'url de l'api
   * @returns {string}
   */
  protected getEntityUrl() {
    return this.baseUrl + this.getEntityName();
  }

}