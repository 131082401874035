import {Observable} from "rxjs";
import {Storage} from "@ionic/storage";
import {Injectable} from '@angular/core';
import {ConnectivityProvider} from "../connectivity/connectivity";
import { ContractorShopService } from 'src/app/services/prestataire/contractor.shop.service';

/*
  Generated class for the ServiceContractorProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class ServiceContractorProvider {

  constructor(private storage: Storage,
              private connectivityService: ConnectivityProvider,
              private subscribeService: ContractorShopService) {

  }

  /**
   * Subscribe on service for contractor
   * @param service
   * @returns {any}
   */
  postSubscribeService(service): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.subscribeService.postSubscribeService(service, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }


    /**
   * Subscribe on service for contractor
   * @param service
   * @returns {any}
   */
     commentaryCustomer(param): Observable<any> {
      // If you are connected to Internet
      if (this.connectivityService.isOnline()) {
        return Observable.create((observer) => {
          this.storage.get('token').then((token) => {
            this.subscribeService.commentaryCustomer(param, token).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          });
        });
      } else {
        return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
      }
    }

  /**
   * UnSubscribe from service for contractor
   * @param service
   * @returns {any}
   */
  unSubscribeService(service): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.subscribeService.unSubscribeService(service, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Update product (prestation) service for contractor
   * @param service
   * @returns {any}
   */
  updateProductService(service): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.subscribeService.updateProductService(service, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Update specific (caractérisques) service for contractor
   * @param specific
   * @returns {any}
   */
  updateSpecific(specific): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.subscribeService.updateSpecificService(specific, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Retrieve specific (caractérisques) service for contractor
   * @param specific
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getSpecific(specific, refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          // if token exists
          if (token) {
            // Reload data even if it is cached
            if (refreshCache) {
              this.subscribeService.clearSpecificCache(); // reinitialize the observable
            }
            this.subscribeService.getSpecificService(specific, token).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Refuse or accept booking for contractor
   * @param booking
   * @returns {any}
   */
  acceptOrRefuseBookingService(booking): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.subscribeService.postAcceptOrRefuseBookingService(booking, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Cancel Paid order from contractor
   * @param order
   * @returns {any}
   */
  cancelTransactionPaidOrder(order): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.subscribeService.cancelTransactionPaidOrder(order, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

}
