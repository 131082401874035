/**
 * Created by Bugatti on 06/10/2017.
 */
import {Injectable} from '@angular/core';
import {BaseService} from "../base.service";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { Auth } from 'src/app/class/auth';


@Injectable({
  providedIn:'root'
})
export class UploadPictureService extends BaseService {

  /**
   *
   * @param http
   */
  constructor(private http: HttpClient) {
    super();
  }

  /**
   *
   * @returns {string}
   */
  getEntityName() {
    return 'savePictureProfil';
  }

  /**
   * Mise à jour de la photo de profil d'un utilisateur via API
   * @param data
   * @param token
   * @returns {Observable<R|T>}
   */
  uploadPicture(data: Object, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(this.getEntityUrl(), this.serialize(data), {headers: headers})
    .pipe( map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
)
  }

  /**
   * Retourne l'url de l'api
   * @returns {string}
   */
  protected getEntityUrl() {
    return this.baseUrl + this.getEntityName();
  }

}