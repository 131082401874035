/**
 * Created by Bugatti on 06/10/2017.
 */
import {Injectable} from '@angular/core';
import {BaseService} from "../base.service";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {catchError, map, publishReplay, refCount} from "rxjs/operators";
import {CacheService} from "ionic-cache";
import { Auth } from 'src/app/class/auth';


@Injectable({
  providedIn:'root'
})
export class UserService extends BaseService {
  

  private _userDetails: Observable<any> = null;
  private fields = '';//fields=ID_CLIENT:NOM_CLIENT:PRENOM_CLIENT:INDICATIF_CLIENT:EMAIL_CLIENT:CIVILITE_CLIENT:TELEPHONE_CLIENT:ADRESSE_CLIENT:IP_INSCRIPTION_CLIENT:DERNIERE_CONNEXION_CLIENT:VERIFICATION_MAIL_CLIENT:PICTURE_URL_B64:ACCOUNTDELETED';
  private authKey = 'auth-offline';
  private _prestationType: Observable<any> = null;



  /**
   *
   * @param {HttpClient} http
   * @param {CacheService} cache
   */
  constructor(private http: HttpClient,
              private cache: CacheService) {
    super();
  }

  /**
   *
   * @returns {string}
   */
  getEntityName() {
    return 'signIn';
  }

  /**
   * Connexion à l'application via API
   * @param user
   * @returns {Observable<R|T>}
   */
  postSignIn(user: Object): Observable<Auth> {
    return this.http.post(this.getEntityUrl(), this.serialize(user), {headers: this.headers})
    .pipe( map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
)
  }

  deleteAccountuser(token) {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };


    return this.http.get(`${this.baseUrl}removeClient`, { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      );
  }

  /**
   * Retourne les informations personnelles d'un user connecté via le token
   * @returns {Observable<R|T>}
   */
  getUserInfos(token,field?): Observable<Auth> {
    let headers = {'Jwt': token};
    
     return  this._userDetails = this.http.get(this.baseUrl + 'getUserInfos' + this.fields, {headers: headers}).pipe(
       map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount() // used to keep the observable alive for as long as there are subscribers.
  )
    // Load with Group key (@returns Observable)
    //return this.cache.loadFromObservable(this.baseUrl + 'getUserInfos' + this.fields, this._userDetails, this.authKey);
  }


  cancelRemoveAccount(token: any,deToken,type) {
    let headers = {'Jwt': token};
    
    return  this._userDetails = this.http.get(`${this.baseUrl}cancelremovesnbcompte?type=${type}&token=${deToken}&source=mobile`, {headers: headers}).pipe(
      map(res => res),
     catchError(this.handleError),
     publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
     refCount()); // used to keep the observable alive for as long as there are subscribers.
  }


  
  /**
   * Retourne les informations personnelles d'un user connecté via le token
   * @returns {Observable<R|T>}
   */
  getToken(): Observable<Auth> {
    
     return  this.http.get(this.baseUrl + 'checkSession').pipe(
       map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount() // used to keep the observable alive for as long as there are subscribers.
  )
    // Load with Group key (@returns Observable)
    //return this.cache.loadFromObservable(this.baseUrl + 'getUserInfos' + this.fields, this._userDetails, this.authKey);
  }

  /**
   * Return all prestations for a contractor by id services (You can call this method whitout token)
   * @param filter
   * @returns {Observable<Auth>}
   */
  getPrestationServicesByType(filter,token): Observable<any> {

    if(token){
      let headers = {'Jwt': token};

      return this.http.get(`${this.baseUrl}getPrestation?type=${filter.type}`,{headers: headers}).pipe(
        map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount()
      )
    }
    return this.http.get(`${this.baseUrl}getPrestation?type=${filter.type}`).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount()
    )
  }



  getAvis(token,field){
    let headers = {'Jwt': token};
    
     return  this._userDetails = this.http.get(this.baseUrl + 'getAvis'+field, {headers: headers}).pipe(
       map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount() // used to keep the observable alive for as long as there are subscribers.
  ) // used to keep the observable alive for as long as there are subscribers.
    
    // Load with Group key (@returns Observable)
    //return this.cache.loadFromObservable(this.baseUrl + 'getUserInfos' + this.fields, this._userDetails, this.authKey);
  }

  /**
   * Reload data even if it is cached
   * Load item from cache if it's in cache or load from origin observable
   */
  refreshCache() {
    this.cache.loadFromDelayedObservable(this.baseUrl + 'getUserInfos' + this.fields, this._userDetails, this.authKey, 90, 'all');
    this._userDetails = null;
  }

  /**
   * Invalidate for a specific group key
   */
  invalidateCache() {
    this.cache.clearGroup(this.authKey);
    this._userDetails = null;
  }


  /**
   * Retourne l'url de l'api
   * @returns {string}
   */
  protected getEntityUrl() {
    return this.baseUrl + this.getEntityName();
  }

}