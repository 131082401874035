import {Observable} from "rxjs";
import {Storage} from "@ionic/storage";
import { Injectable } from '@angular/core';
import {ConnectivityProvider} from "../connectivity/connectivity";
import { UpdateContractorService } from 'src/app/services/prestataire/update.contractor.service';

/*
  Generated class for the UpdateContractorProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class UpdateContractorProvider {

  constructor(private storage: Storage,
              private connectivityService: ConnectivityProvider,
              private updateCo: UpdateContractorService) {

  }

  /**
   * Mise à jour des données d'un prestataire connecté
   * @param data
   * @returns {any}
   */
  updateCoInformations(data): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.updateCo.updateCoInfos(data, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Mise à jour de l'email d'un prestataire connecté
   * @param data
   * @returns {any}
   */
  updateCoEmail(data): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.updateCo.updateEmail(data, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Mise à jour de la photo de profil d'un prestataire connecté
   * @param image
   * @returns {any}
   */
  uploadPciture(image): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.updateCo.uploadPicture(image, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors de la mise à jour de la photo' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Delete media
   * @param media
   * @returns {any}
   */
  deleteMedia(media): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.updateCo.deleteMediaService(media, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors de la suppression du media' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Add new media (contractor)
   * @param media
   * @returns {any}
   */
  addMedia(media): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.updateCo.postMediaService(media, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors de la mise à jour de la photo' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Retrieve MediasAlbum service for contractor
   * @param mediasAlbumId
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getMediasAlbum(mediasAlbumId, refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          // if token exists
          if (token) {
            // Reload data even if it is cached
            if (refreshCache) {
              this.updateCo.clearMediaAlbumCache(); // reinitialize the observable
            }
            this.updateCo.getMediasAlbumService(mediasAlbumId, token).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des photos' || err);
            });
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }


  /**
   * Create new album (contractor)
   * @param album
   * @returns {any}
   */
  createAlbum(album): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.updateCo.postAlbum(album, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors de la création de votre album' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Update album (contractor)
   * @param album
   * @returns {any}
   */
  updateAlbum(album): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.updateCo.putAlbum(album, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors de la création de votre album' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Delete album
   * @param album
   */
  deleteAlbum(album): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.updateCo.deleteAlbum(album, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors de la suppression de l\'album' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

}
