import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GuardConnect } from './guard/guardconnect';
import { CheckToken } from './guard/token';
import { SearchLocationPage } from './pages/search-location/search-location';

const routes: Routes = [
  {
    path: 'homeCoPage',
    loadChildren: () => import('./contractors/home-co/home-co.module').then(m => m.HomeCoPageModule)
  },

  {
    path: 'dash', 
    loadChildren: () => import('./contractors/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },

  {
    path: 'homePage',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
    ,canLoad:[ GuardConnect]
  },

  
  

  {
    path: 'help',
    loadChildren: () => import('./pages/usinghelp/help.module').then(m => m.UsinghelpPageModule)
  },
  {
    path: 'noticePage',
    loadChildren: () => import('./pages/notice/notice.module').then(m => m.NoticePageModule)
  },


  {
    path: 'booking',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule)
    ,canLoad:[ CheckToken] 
  },

  {
    path: 'bookingCo',
    loadChildren: () => import('./contractors/orders-co/orders-co.module').then(m => m.OrdersCoPageModule)
    ,canLoad:[ CheckToken] 
  },


  {
    path: 'thank',
    loadChildren: () => import('./pages/thank/thank.module').then(m => m.ThankModule)
  },


  {
    path: 'searchLocationPage',
    component: SearchLocationPage
  },


  {
    path: 'displayVideoPage',
    loadChildren: () => import('./pages/display-video/display-video.module').then(m => m.DisplayVideoPageModule)
  },

  {
    path: 'updateAlbumCoPage',
    loadChildren: () => import('./contractors/update-album-co/update-album-co.module').then(m => m.UpdateAlbumCoPageModule)
    ,canLoad:[ CheckToken] 
  },
  {
    path: 'ribCoPage',
    loadChildren: () => import('./contractors/rib-co/rib-co.module').then(m => m.RibCoPageModule)
    ,canLoad:[ CheckToken] 
  },

  {
    path: 'updateBarber',
    loadChildren: () => import('./contractors/update-barber-shop-co/update-barber-shop-co.module').then(m => m.UpdateBarberShopCoPageModule)
    ,canLoad:[ CheckToken] 
  },

  {
    path: 'albumCoPage',
    loadChildren: () => import('./contractors/album-co/album-co.module').then(m => m.AlbumCoPageModule)
    ,canLoad:[ CheckToken] 
  },
  {
    path: 'displayMediaPage',
    loadChildren: () => import('./contractors/display-media-co/display-medias-co.module').then(m => m.DisplayMediaCoPageModule)
    ,canLoad:[ CheckToken] 
  },
  {
    path: 'subCategoryCoPage',
    loadChildren: () => import('./contractors/sub-category-co/sub-category-co.module').then(m => m.SubCategoryCoPageModule)
  },

  {
    path: 'subCategoryBisPage',
    loadChildren: () => import('./pages/sub-category-bis/sub-category-bis.module').then(m => m.SubCategoryBisPageModule)
  },

  {
    path: 'subCategoryPage',
    loadChildren: () => import('./pages/sub-category/sub-category.module').then(m => m.SubCategoryPageModule)
 
  },

  {
    path: 'serviceDetailPage',
    loadChildren: () => import('./pages/service-detail/service-detail.module').then(m => m.ServiceDetailPageModule)
  },

  {
    path: 'feedsReservationPage',
    loadChildren: () => import('./pages/feeds-reservation/feeds-reservation.module').then(m => m.FeedsReservationPageModule)
  },

  {
    path: 'feedsReservationco',
    loadChildren: () => import('./contractors/feeds-reservation-co/feeds-reservation-co.module').then(m => m.FeedsReservationCoPageModule)
  },

  {
    path: 'feedsAlbumByContractorPage',
    loadChildren: () => import('./pages/feeds-album-by-contractor/feeds-album-by-contractor.module').then(m => m.FeedsAlbumByContractorPageModule)
  },

  {
    path: 'prestation',
    loadChildren: () => import('./pages/prestation/prestation.module').then(m => m.PrestationPageModule)
    
  },
  {
    path: 'loginCoPage',
    loadChildren: () => import('./contractors/login-co/login-co.module').then(m => m.LoginCoPageModule)
  },

  {
    path: 'resetPasswordPage',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },

  {
    path: 'resetPasswordCoPage',
    loadChildren: () => import('./contractors/reset-password-co/reset-password-co.module').then(m => m.ResetPasswordCoPageModule)
  },

  {
    path: 'SubCategoryTwoCoPage',
    loadChildren: () => import('./contractors/sub-category-two-co/sub-category-two-co.module').then(m => m.SubCategoryTwoCoPageModule)
  },

 
  {
    path: 'barberCoPage',
    loadChildren: () => import('./contractors/barber-shop-co/barber-shop-co.module').then(m => m.BarberShopCoPageModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },

  {
    path: 'welcomePage',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  
  },

  {
    path: 'picture',
    loadChildren: () => import('./contractors/picture-co/picture-co.module').then(m => m.PictureCoPageModule)
    ,canLoad:[ CheckToken] 
  },

  {
    path: 'scanner',
    loadChildren: () => import('./contractors/scanner-qr-code-co/scanner-qr-code-co.module').then(m => m.ScannerQrCodeCoPageModule)
    ,canLoad:[ CheckToken] 
  },




  {
    path: 'registerPage',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },

  {
    path: 'openCalendarTimeModalPage',
    loadChildren: () => import('./pages/open-calendar-time-modal/open-calendar-time-modal.module').then(m => m.OpenCalendarTimeModalPageModule)
    ,canLoad:[ CheckToken] 
  },



  {
    path: 'profil',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
    ,canLoad:[ CheckToken] 
  },

  {
    path: 'calendar',
    loadChildren: () => import('./contractors/contractors-calendar-customer/contractors-calendar-customer.module').then(m => m.ContractorsCalendarCustomerPageModule)
    
  
  },

  {
    path: 'profilco',
    loadChildren: () => import('./contractors/profile-co/profile-co.module').then(m => m.ProfileCoPageModule),
    canLoad:[ CheckToken] 
  },

  {
    path: 'offer',
    loadChildren: () => import('./pages/feeds-tendance/feeds-tendance.module').then(m => m.FeedsTendancePageModule)
    
  },

  {
    path: 'feedsCoPage',
    loadChildren: () => import('./contractors/feeds-co/feeds-co.module').then(m => m.FeedsCoPageModule)
  },
  {
    path: 'feedscoservice',
    loadChildren: () => import('./contractors/feeds-service-co/feeds-service-co.module').then(m => m.FeedsServiceCoPageModule)
    ,canLoad:[ CheckToken] 
  },

  {
    path: 'productCoPage',
    loadChildren: () => import('./contractors/product-co/product-co.module').then(m => m.ProductCoPageModule)
    ,canLoad:[ CheckToken] 
  },


  {
    path: 'filterResultsPage',
    loadChildren: () => import('./pages/filter-results/filter-results.module').then(m => m.FilterResultsPageModule)
  },

  {
    path: 'filterResultsCoPage',
    loadChildren: () => import('./contractors/filter-results-co/filter-results-co.module').then(m => m.FilterResultsCoPageModule)
  },

  {
    path: 'mediaCoPage',
    loadChildren: () => import('./contractors/media-co/media-co.module').then(m => m.MediaCoPageModule)
    ,canLoad:[ CheckToken] 
  },



  {
    path: '',
    redirectTo: 'homePage',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
