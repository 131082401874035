import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { OrderUserProvider } from '../../providers/order-user/order-user';

/**
 * Generated class for the EvaluationComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */

@Component({
  selector: 'evaluation',
  templateUrl: 'evaluation.html',
  styleUrls: ['./evaluation.scss'],
  providers: [FormBuilder]
})
export class EvaluationComponent {
  @ViewChild('myInput', { read: ElementRef }) myInput: ElementRef;
  text: string;
  noteForm;
  @Input() idReservation;
  rating;
  imageUrl = 'assets/img/logo.png';
  disableBtn = false;

  constructor(private fb: FormBuilder,
    private noteProvider: OrderUserProvider,
    private toastCtrl: ToastController,
    public viewCtrl: ModalController
  ) {

  }

  ngOnInit() {
    this.noteForm = this.fb.group({
      comment: ['', {
        validators: [Validators.required]
      }],
      rating: ['', {
        validators: [Validators.required]
      }]
    });

    this.noteForm.valueChanges
      .subscribe((changedObj: any) => {
        this.disableBtn = this.noteForm.valid;
      });

    this.resize(55, "");
  }

  /**
* Function: Rating
*/
  send() {
    let rating = {
      note: this.noteForm.value.rating,
      comment: this.noteForm.value.comment,
      id_reservation: this.idReservation
    };

    if (!this.noteForm.value.rating) {
      this.toastNotification("Veuillez laisser une note");

    }
    else if (!this.noteForm.value.comment) {
      this.toastNotification("Veuillez laisser un commentaire");

    }
    else {
      this.noteProvider.noteContractor(rating).subscribe((result) => {
        this.dismiss(result);
      }, (error) => {
        this.dismiss(error);
      });
    }

  }


  async toastNotification(msg: any, position: string = 'bottom') {
    let toast = await this.toastCtrl.create({
      message: msg,
      position: "bottom",
      cssClass: 'dark-trans',
      buttons: [
        {
          side: 'end',
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ]
    });
    toast.present()
  }


  /**
* Close
*/
  dismiss(data?) {
    this.viewCtrl.dismiss(data);
  }


  resize(value, element) {
    let textArea;
    if (element) {
      textArea = element;
      textArea.style.overflow = 'hidden';
      textArea.style.height = 'auto';
      var scrollHeight = textArea.scrollHeight;
      if (value) {
        textArea.style.height = value + 'px!important';
      }
      else {
        textArea.style.height = scrollHeight + 'px ';
      }
    }

  }

}
