import { Injectable } from "@angular/core";
import { CanLoad, Router, Route, UrlTree, CanActivate, UrlSegment } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Events } from "../common/events";
import { UserService } from "../services/client/user.service";
import { ContractorService } from "../services/prestataire/contractor.service";


@Injectable(
    {
        providedIn: 'root'
    }
)
export class CheckToken implements CanLoad {

    constructor(public router: Router,
        private events: Events, private userService: UserService, private contractorService: ContractorService
    ) {

    }
    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        return Observable.create((observer) => {


            if (localStorage.getItem('hierarchy') == 'CO') {
                this.contractorService.getContractorInfos(localStorage.getItem('token'), '').subscribe((result) => {
                    if (!result.succes) {

                        this.goToCO();

                    }
                    observer.next(true);
                    observer.complete();
                }, (err) => {
                    observer.error('Impossible de se connecter au serveur' || err);
                })
            }

            else if (localStorage.getItem('hierarchy') == 'CL') {
                this.userService.getUserInfos(localStorage.getItem('token')).subscribe((result) => {
                    if (!result.succes) {

                        this.goToCL();

                    }
                    observer.next(true);
                    observer.complete();
                }, (err) => {
                    observer.error('Impossible de se connecter au serveur' || err);
                })
            }
        });


    }


    /**
   * Go to CO hierarchy
   */
    private goToCO() {
        this.events.publish('contractor:checkLocalStorage')
        this.events.publish('homeCoPage:checkcPage');
        this.router.navigateByUrl('homeCoPage');
        localStorage.setItem("isConnectedButton", "0");
    }

    /**
     * Go to CL hierarchy
     */
    private goToCL() {

        localStorage.setItem("accesHomePage", "0");
        this.events.publish('user:checkLocalStorage');
        localStorage.setItem("isConnectedButton", "0");
        this.router.navigateByUrl("/homePage");
    }
 }