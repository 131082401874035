import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Storage} from "@ionic/storage";
import {ConnectivityProvider} from "../connectivity/connectivity";
import { UpdateUserService } from 'src/app/services/client/update.user.service';

/*
  Generated class for the UpdateUserProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class UpdateUserProvider {

  /**
   *
   * @param {Storage} storage
   * @param {ConnectivityProvider} connectivityService
   * @param {UpdateUserService} updateUser
   */
  constructor(private storage: Storage,
              private connectivityService: ConnectivityProvider,
              private updateUser: UpdateUserService) {
  }

  /**
   * Mise à jour des données d'un utilisateur connecté
   * @param data
   * @returns {any}
   */
  updateUserInformations(data): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.updateUser.updateUserInfos(data, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Mise à jour de l'email d'un utilisateur connecté
   * @param data
   * @returns {any}
   */
  updateEmail(data): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.updateUser.updateEmail(data, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Mise à jour de la photo de profil d'un utilisateur connecté
   * @param image
   * @returns {any}
   */
  uploadPciture(image): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.updateUser.uploadPicture(image, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors de la mise à jour de la photo' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Provider : Retrieve list of media services SoulnBeauty
   * @param service
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getMedia(service: any, refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          if (token) {
            // Reload data even if it is cached
            if (refreshCache) {
              this.updateUser.clearMediaCache(); // reinitialize the observable
            }
            this.updateUser.getMediaService(service, token).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }
}
