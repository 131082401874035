/**
 * Created by Bugatti on 06/10/2017.
 */
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {BaseService} from "../base.service";
import {catchError, map, publishReplay, refCount} from "rxjs/operators";
import { Auth } from 'src/app/class/auth';


@Injectable({
  providedIn:'root'
})
export class UpdateContractorService extends BaseService {

  private _coMediasAlbum: Observable<any> = null;

  /**
   *
   * @param http
   */
  constructor(private http: HttpClient) {
    super();
  }

  /**
   *
   * @returns {string}
   */
  getEntityName() {
    return 'updatePrestataireInfos';
  }

  clearMediaAlbumCache() {
    this._coMediasAlbum = null;
  }

  /**
   * Mise à jour des informations d'un prestataire via API
   * @param data
   * @param token
   * @returns {Observable<R|T>}
   */
  updateCoInfos(data: Object, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(this.getEntityUrl(), this.serialize(data), {headers: headers})
    .pipe( map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
)
  }

  /**
   *
   * @param {Object} data
   * @param token
   * @returns {Observable<Auth>}
   */
  updateEmail(data: Object, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(this.baseUrl + 'updateUsermail', this.serialize(data), {headers: headers})
    .pipe( map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
)
  }

  /**
   * Mise à jour de la photo de profil d'un utilisateur via API
   * @param image
   * @param token
   * @returns {Observable<R|T>}
   */
  uploadPicture(image: any, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}updatePicturePrestataire`, this.serialize(image), {headers: headers})
    .pipe( map(res => res),
     catchError(this.handleError),
     publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
     refCount() // used to keep the observable alive for as long as there are subscribers.
 )
  }

  /**
   * Delete media via API
   * @param media
   * @param token
   * @returns {Observable<R|T>}
   */
  deleteMediaService(media: Object, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}removeMediaServices`, this.serialize(media), {headers: headers})
    .pipe( map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
)
  }

  /**
   * Add new Media via API
   * @param image
   * @param token
   * @returns {Observable<R|T>}
   */
  postMediaService(image: any, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}setMediaServices`, this.serialize(image), {headers: headers})
    .pipe( map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
)
  }

  /**
   * Create album for contractor via API
   * @param album
   * @param token
   * @returns {Observable<R|T>}
   */
  postAlbum(album: any, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}createAlbumPrestation`, this.serialize(album), {headers: headers})
    .pipe( map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
)
  }

  /**
   * Retrieve MediasAlbum via le token
   * @param specific
   * @param token
   * @returns {Observable<Auth>}
   */
  getMediasAlbumService(specific: any, token: any): Observable<Auth> {
    let headers = {'Jwt': token};
    let fields = `?id_album=${specific.id_album}`;
    return this.http.get(`${this.baseUrl}getMediasAlbum${fields}`, {headers: headers}).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  /**
   * Update album via API
   * @param album
   * @param token
   * @returns {Observable<R|T>}
   */
  putAlbum(album: any, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}updateAlbumPrestation`, this.serialize(album), {headers: headers})
    .pipe( map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
)
  }

  /**
   * Delete album via API
   * @param album
   * @param token
   * @returns {Observable<R|T>}
   */
  deleteAlbum(album: any, token): Observable<Auth> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}removeAlbumPrestation`, this.serialize(album), {headers: headers})
    .pipe( map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
)
  }

  /**
   * Retourne l'url de l'api
   * @returns {string}
   */
  protected getEntityUrl() {
    return this.baseUrl + this.getEntityName();
  }

}
