import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Events } from 'src/app/common/events';
import { ContractorForgetPasswordService } from 'src/app/services/prestataire/contractor.forget.password.service';
import { ContractorService } from 'src/app/services/prestataire/contractor.service';
import {Storage} from "@ionic/storage";
import {ConnectivityProvider} from "../connectivity/connectivity";

/*
  Generated class for the AuthContractorProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class AuthContractorProvider {

  private _token;
  private infoUser;

  constructor(private contractorService: ContractorService,
              private forgetPassword: ContractorForgetPasswordService,
              private connectivityService: ConnectivityProvider,
              private storage: Storage,
              private events: Events) {
  }



  setInfoUser(userInfo){

    this.infoUser =  userInfo;
  }

  getInfoUser(){

    return this.infoUser;
  }

  /**
   * Connexion Provider : Permets d'authentifier un prestataire
   * @param user
   * @returns {any}
   */
  signIn(user: Object): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.contractorService.postSignIn(user).subscribe((user) => {
          // set token if user found in DB
          if (user.succes) {
            this._token = user.result;
            this.storage.set('token', user.result);
            localStorage.setItem('token', user.result);
            // publish an event when a user is logging into app
            this.events.publish('contractor:login');
          }
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(user);
          observer.complete();
        }, (err) => {
          observer.error('Impossible de se connecter au serveur' || err);
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * On va initialiser le mot de passe de l'utilisateur à partir de son mail
   * @param email
   * @returns {any}
   */
  resetPassword(email) {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.forgetPassword.postInitPassword(email).subscribe((result) => {
          // push into subject and call complete if you want to close this stream (like a promise)
          observer.next(result);
          observer.complete()
        }, (err) => {
          let error = 'Une erreur est survenue lors de la connexion au serveur';
          observer.error('Connexion au serveur impossible' || error);
        })
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

    /**
   * On va renvoyer un lien de confirmation à l'utilisateur
   * @param user
   * @returns {any}
   */
  resetConfirmMail(user) {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.forgetPassword.resetConfirmMail(user).subscribe((result) => {
          // push into subject and call complete if you want to close this stream (like a promise)
          observer.next(result);
          observer.complete()
        }, (err) => {
          let error = 'Une erreur est survenue lors de la connexion au serveur';
          observer.error('Connexion au serveur impossible' || error);
        })
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }


    /**
   * On va renvoyer un lien de confirmation à l'utilisateur
   * @param user
   * @returns {any}
   */
     public deleteAccountuser(){
      // If you are connected to Internet
      if (this.connectivityService.isOnline()) {

           // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.contractorService.deleteAccountuser(token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error(`Une erreur est survenue lors de l'enregistrement` || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
    }
  }

  /**
   * On récupère le token sauvegardé dans le localStorage
   * dont on se sert pour récupérer les informations personnelles d'un user
   * @returns {any}
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  checkTokenLocalStorage(refreshCache: boolean = false, field = ''): Observable<any> {
    // If you are connected to Internet
    return Observable.create((observer) => {
      //Load token if exists
      this.storage.get('token').then((token) => {
        // if token exists
        if (token) {
          // Reload data even if it is cached
          if(refreshCache) {
            this.contractorService.refreshCache();
          }
          this.contractorService.getContractorInfos(token,field).subscribe((result) => {
            this.storage.set('user_id', result.result.ID_PRESTATEUR);
            result.result['token'] = token;
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Impossible de se connecter au serveur' || err);
          })
        } else {
          console.log('no token')
        }
      });
    });
  }

  profilContractor(refreshCache: boolean = false, field = ''): Observable<any> {
    // If you are connected to Internet
    return Observable.create((observer) => {
      //Load token if exists
      this.storage.get('token').then((token) => {
        // if token exists
        if (token) {
          // Reload data even if it is cached
          if(refreshCache) {
            this.contractorService.refreshCache();
          }
          this.contractorService.getContractorInfos(token,field).subscribe((result) => {
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Impossible de se connecter au serveur' || err);
          })
        } else {
          console.log('no token')
        }
      });
    });
  }


  /**
   * On récupère le token de l'utilisateur qui vient de se connecter à l'app
   * dont on se sert pour récupérer les informations personnelles d'un user
   * @returns {any}
   */
  getToken(): Observable<any> {
    return Observable.create((observer) => {
      this.contractorService.getContractorInfos(this._token).subscribe((result) => {
        this.storage.set('user_id', result.result.ID_PRESTATEUR);
        this.storage.set('hierarchy', 'CO');
        localStorage.setItem('hierarchy', 'CO');
        observer.next(result);
        observer.complete();
      }, (err) => {
        observer.error('Impossible de se connecter au serveur' || err);
      })
    });
  }

  /**
   * On supprime le token de l'utilisateur après sa déconnexion
   */
  logout() {
    this.contractorService.invalidateCache(); // clear cache
    this.storage.remove('token').then(() => {
      console.log("remove token")
    });
    this.storage.remove('user_id').then(() => {
      console.log("remove user_id")
    });
    this.storage.remove('hierarchy').then(() => {
      console.log("remove hierarchy")
    });
    this.storage.remove('lang').then(() => {
      console.log("remove lang")
    });
  }

}
