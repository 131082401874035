import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map, publishReplay, refCount } from "rxjs/operators";
import { BaseService } from "./base.service";

@Injectable({
    providedIn:'root'
  })
  export class LinkService extends BaseService {
 
    constructor(private http: HttpClient){
        super();
    }
    /**
     * 
     * @returns  all urls need by the application
     */
    public getUrls(): Observable<any> {
         return  this.http.get(this.baseUrl + 'getSnbUrl').pipe(
           map(res => res),
          catchError(this.handleError),
          publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
          refCount() // used to keep the observable alive for as long as there are subscribers.
      )
  }

}
  