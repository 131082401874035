import { NgModule } from '@angular/core';
import {CategoryUserComponent} from "./category-user";
import {LoaderSpinnerComponentModule} from "../loader-spinner/loader-spinner.module";
import {SkeletonItemComponent} from "../skeleton/skeleton-item";
import { OrderModule } from 'ngx-order-pipe';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    LoaderSpinnerComponentModule,
    OrderModule
  ],
  declarations: [
    CategoryUserComponent,
    SkeletonItemComponent
  ],
  exports: [
    CategoryUserComponent,
    SkeletonItemComponent
  ]
})
export class CategoryUserComponentModule {}
