
// AoT requires an exported function for factories
// The translate loader needs to know where to load i18n files

import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, ErrorHandler } from "@angular/core";
import { FormBuilder, FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner/ngx";
import { Camera } from "@awesome-cordova-plugins/camera/ngx";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { IonicModule, NavParams } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";
import { CacheModule } from "ionic-cache";
import { TruncateModule } from "ng2-truncate";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { Countries } from "./common/phone-validator/countries";
import { CategoryUserComponentModule } from "./components/category-user/category-user.module";
import { SkeletonItemComponent } from "./components/skeleton/skeleton-item";
import { GlobalHttpInterceptorService } from "./interceptorhttp/intercepte";
import { FormError } from "./lang/form-error/error-message";
import { SearchLocationPageModule } from "./pages/search-location/search-location.module";
import { AuthContractorProvider } from "./providers/auth-contractor/auth-contractor";
import { AuthUserProvider } from "./providers/auth-user/auth-user";
import { ChangePasswordContractorProvider } from "./providers/change-password-contractor/change-password-contractor";
import { ChangePasswordUserProvider } from "./providers/change-password-user/change-password-user";
import { ConnectivityProvider } from "./providers/connectivity/connectivity";
import { Database, Cart } from "./providers/database";
import { MatchPasswordValidatorProvider } from "./providers/match-password-validator/match-password-validator";
import { OrderContractorProvider } from "./providers/order-contractor/order-contractor";
import { OrderUserProvider } from "./providers/order-user/order-user";
import { PaidOrdersUserProvider } from "./providers/paid-orders-user/paid-orders-user";
import { ServiceContractorProvider } from "./providers/service-contractor/service-contractor";
import { ShopServicesProvider } from "./providers/shop-services/shop-services";
import { UpdateContractorProvider } from "./providers/update-contractor/update-contractor";
import { UpdateUserProvider } from "./providers/update-user/update-user";
import { ChangePasswordService } from "./services/client/change.password.service";
import { ForgetPasswordService } from "./services/client/forget.password.service";
import { OrdersService } from "./services/client/orders.service";
import { RegisterService } from "./services/client/register.service";
import { UpdateUserService } from "./services/client/update.user.service";
import { UploadPictureService } from "./services/client/upload.picture.service";
import { UserService } from "./services/client/user.service";
import { ContractorChangePasswordService } from "./services/prestataire/contractor.change.password.service";
import { ContractorForgetPasswordService } from "./services/prestataire/contractor.forget.password.service";
import { ContractorService } from "./services/prestataire/contractor.service";
import { ContractorShopService } from "./services/prestataire/contractor.shop.service";
import { UpdateContractorService } from "./services/prestataire/update.contractor.service";
// in Ionic's static asset pipeline.
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }


 import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 import {CalendarModule} from 'primeng/calendar';
import { TabsPageModule } from "./components/tabs/tabs.module";

const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAgMebSAM2zOaycLZ73A010AWRwH7CkzJw",
    authDomain: "soulnbeauty-71367.firebaseapp.com",
    databaseURL: "https://soulnbeauty-71367.firebaseio.com",
    projectId: "soulnbeauty-71367",
    storageBucket: "soulnbeauty-71367.appspot.com",
    messagingSenderId: "868618533226"
  }
};


// firebase.initializeApp(environment.firebase);

// Application wide providers
const APP_PROVIDERS = [
  FormError,
  Network,
  // Crop,
  ChangePasswordService,
  ForgetPasswordService,
  RegisterService,
  Countries,
  UpdateUserService,
  UploadPictureService,
  UserService,
  ContractorService,
  ContractorForgetPasswordService,
  ContractorChangePasswordService,
  ContractorShopService,
  UpdateContractorService,
  OrdersService,
  Camera,
  BarcodeScanner
];

@NgModule({
  declarations: [
    AppComponent,
    //PoppictureComponent
  ],
  imports: [
    BrowserModule,

    BrowserAnimationsModule ,
    CategoryUserComponentModule,
    HttpClientModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    CalendarModule,
    TruncateModule,
    SearchLocationPageModule,
    IonicModule.forRoot({
      rippleEffect: true,
      mode: 'md',
    }),
    CacheModule.forRoot(),
    IonicStorageModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SkeletonItemComponent
  ],
  providers: [
    APP_PROVIDERS,
  // { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
    Storage,
    FormBuilder,
    // AppVersion,
    // LocalNotifications ,
    // StatusBar,
    Geolocation,
    NavParams,
    // Deeplinks,
    // MessagingService,
    // CallNumber,
    // SplashScreen,FCM,
    // BackgroundMode ,
    InAppBrowser,
    // { provide: ErrorHandler, useClass: IonicErrorHandler },
    Database,
    // Badge,
    Cart,
    MatchPasswordValidatorProvider,
    AuthUserProvider,
    ConnectivityProvider,
    UpdateUserProvider,
    AuthContractorProvider,
    ChangePasswordContractorProvider,
    UpdateContractorProvider,
    ShopServicesProvider,
    OrderUserProvider,
    ChangePasswordUserProvider,
    PaidOrdersUserProvider,
    ServiceContractorProvider,
    OrderContractorProvider
  ]
})
export class AppModule { }
