/**
 * Created by Bugatti on 06/10/2017.
 */
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

@Injectable({
  providedIn:'root'
})
export class BaseService {

  protected headers;
  protected baseUrl = 'https://market.soulnbeauty.be/';

  constructor() {
    this.headers = {'Content-type': 'application/x-www-form-urlencoded'};
  }

  /**
   * Serialisation des données
   * @param obj
   * @returns {string}
   */
  protected serialize(obj: Object): String {
    var str = [], p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = p, v = obj[p];
        if (v != null && k != "id") {
          if (v !== null && typeof v === "object") {
            if (k == 'caracteristiques') {
              str.push(k + ":"  + JSON.stringify(v));
              let id;
              for (id in v) {
              }
            } else {
              str.push(this.serialize(v));
            }
          } else {
            str.push(k + "=" + encodeURIComponent(v));
          }
        }
      }
    }
    return str.join("&");
  }

  /**
   * Récupère les erreurs survenues lors de la connexion à l'API
   * @param {Response | any} error
   * @returns {Observable<any>}
   */
  protected handleError(error: Response | any): Observable<any> {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Observable.throw(errMsg);
  }
}