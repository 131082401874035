/**
 * Created by Bugatti on 30/10/2017.
 */

export class FormError {
  
  validation_messages = {
    'firstname': [
      {type: 'required', message: '* Ce champs est requis.'},
      {type: 'pattern', message: '* Le nom doit contenir uniquement des lettres.'}
    ],
    "gender": [
      {type: 'required', message: '* Ce champs est requis.'}
    ],
    'lastname': [
      {type: 'required', message: '* Ce champs est requis.'},
      {type: 'pattern', message: '* Le nom doit contenir uniquement des lettres.'}
    ],
    'address': [
      {type: 'required', message: '* Ce champs est requis.'}
    ],
    'description': [
      {type: 'required', message: '* Ce champs est requis.'}
    ],
    'name': [
      {type: 'required', message: '* Ce champs est requis.'}
    ],
    'birthDay' : [
      {type: 'required', message: '* Ce champs est requis.'}
    ],
    'phone': [
      {type: 'required', message: '* Ce champs est requis.'},
      {type: 'validCountryPhone', message: '* Numéro de téléphone incorrect pour le pays sélectionné'}
    ],
    'terms': [
      {type: 'pattern', message: 'You must accept terms and conditions.'}
    ],
    'email': [
      {type: 'pattern', message: '* Veuillez entrer une adresse email valide.'},
      {type: 'email', message: '* Veuillez entrer une adresse email valide.'},
      {type: 'required', message: '* Ce champs est requis'}
    ],
    'password': [
      {type: 'required', message: '* Ce champs est requis'}
    ],
    'country': [
      {type: 'required', message: '* Ce champs est requis'}
    ],
    'bankName': [
      {type: 'required', message: '* Ce champs est requis'}
    ],
    'username': [
      {type: 'required', message: '* Ce champs est requis'}
    ],
    'rib': [
      {type: 'required', message: '* Ce champs est requis'},
      {type: 'rib', message: 'RIB invalide'}
    ],
    'iban': [
      {type: 'required', message: '* Ce champs est requis'},
      {type: 'iban', message: 'IBAN invalide'}
    ],
    'swift': [
      {type: 'required', message: '* Ce champs est requis'},
      {type: 'swift', message: 'SWIFT invalide'}
    ],
    'placeDeparture': [
      {type: 'required', message: '* Choisissez votre lieu de départ'}
    ],
    'placeArrival': [
      {type: 'required', message: '* Choisissez votre lieu d\'arriver'}
    ],
    'weight': [
      {type: 'required', message: '* Ce champs est requis'}
    ],
    'price': [
      {type: 'required', message: '* Ce champs est requis'}
    ],
    'quantite': [
      {type: 'required', message: '* Ce champs est requis'}
    ],
    'garantie': [
      {type: 'required', message: '* Ce champs est requis'}
    ]
  };
  
  constructor() {
  }
}