import {Injectable} from '@angular/core';
import {Storage} from "@ionic/storage";
import { Observable } from 'rxjs';
import { Events } from 'src/app/common/events';
import { UserService } from 'src/app/services/client/user.service';
import { ContractorShopService } from 'src/app/services/prestataire/contractor.shop.service';
import {ConnectivityProvider} from "../connectivity/connectivity";

/*
  Generated class for the ShopServicesProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class ShopServicesProvider {

  constructor(private storage: Storage,
              private connectivityService: ConnectivityProvider,
              private prestationTypeService: UserService,
              private shopService: ContractorShopService,
              private events: Events) {
  }

  /**
   * Shop Provider : Retrieve list of category services SoulnBeauty
   * @param {boolean} refreshCache
   * @param {number} unique
   * @param {number} limit
   * @returns {Observable<any>}
   */
  getShopServices(refreshCache: boolean = false, unique: number = 0, limit: number = 20): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.shopService.getShopService(unique, limit).subscribe((result) => {
          // Reload data even if it is cached
          if (refreshCache) {
            this.shopService.clearServicesCache(); // reinitialize the observable
          }
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error('Une erreur est survenue lors du chargement des données' || err);
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Shop Provider : Retrieve list of category SoulnBeauty
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getCategories(refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.shopService.getCategoriesService().subscribe((result) => {
          // Reload data even if it is cached
          if (refreshCache) {
            this.shopService.clearCategoriesCache(); // reinitialize the observable
          }
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error('Une erreur est survenue lors du chargement des données' || err);
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  getServicesFromCategories(categoryId: number, refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    
      return new Observable((observer) => {
        this.shopService.getShopServiceFromCategory(categoryId).subscribe((result) => {
          // Reload data even if it is cached
          if (refreshCache) {
            this.shopService.clearServiceFromCategoriesCache(); // reinitialize the observable
          }
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error('Une erreur est survenue lors du chargement des données' || err);
        });
      });

    // } else {
    //   return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    // }
  }


  getPrestationServicesBycategoryId(categoryId: number, refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.shopService.getPrestationServicesBycategoryId(categoryId).subscribe((result) => {
          // Reload data even if it is cached
          if (refreshCache) {
            this.shopService.clearServiceFromCategoriesCache(); // reinitialize the observable
          }
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error('Une erreur est survenue lors du chargement des données' || err);
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

    getPrestationBycategoryId(serviceId: number, refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          
            this.shopService.getPrestationBycategoryId(serviceId,token).subscribe((result) => {
              // Reload data even if it is cached
              if (refreshCache) {
                this.shopService.clearServiceFromCategoriesCache(); // reinitialize the observable
              }
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          
        });
       
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }



  getPrestationById(serviceId: number, refreshCache: boolean = false): Observable<any> {
  // If you are connected to Internet
  if (this.connectivityService.isOnline()) {
    return Observable.create((observer) => {
      this.shopService.getPrestationServicesById(serviceId).subscribe((result) => {
        // Reload data even if it is cached
        if (refreshCache) {
          this.shopService.clearServiceFromCategoriesCache(); // reinitialize the observable
        }
        //call complete if you want to close this stream (like a promise)
        //push into subject
        observer.next(result);
        observer.complete();
      }, (err) => {
        observer.error('Une erreur est survenue lors du chargement des données' || err);
      });
    });

  } else {
    return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
  }
}



  


  /**
   *
   * @param {boolean} refreshCache
   * @param {number} subCategoryId
   * @returns {Observable<any>}
   */
  getSubCategoryServices(refreshCache: boolean = false, subCategoryId: number): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.shopService.getSubCategoryServices(subCategoryId).subscribe((result) => {
          // Reload data even if it is cached
          if (refreshCache) {
            this.shopService.clearSubCategoryCache(); // reinitialize the observable
          }
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error('Une erreur est survenue lors du chargement des données' || err);
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }
  

   /**
   * @returns {any}
   */
  getNoteStatus(refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          if (token) {
            // Reload data even if it is cached
            if (refreshCache) {
              this.shopService.clearCalendarCache(); // reinitialize the observable
            }
            this.shopService.getNoteStatus(token).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }
  /**
   * Calendar Provider : Retrieve list of calendar services SoulnBeauty
   * @returns {any}
   */
  getContractorCalendarServices(refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          if (token) {
            // Reload data even if it is cached
            if (refreshCache) {
              this.shopService.clearCalendarCache(); // reinitialize the observable
            }
            this.shopService.getContractorCalendarService(token).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * My Shop Provider : Retrieve list of subscribe services from contractor SoulnBeauty
   * @param refreshCache
   * @returns {Observable<any>}
   */
  getMyShopServices(field): Observable<any> {

    if(field==null){
      field = "";
    }
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          if (token) {
            // Reload data even if it is cached
            // if (refreshCache) {
            //   this.shopService.clearMyServicesCache(); // reinitialize the observable
            // }
            this.shopService.getMyService(token,field).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   *
   * @param idPrestion
   * @returns {Observable<any>}
   */
  getServicesById(idPrestion): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          if (token) {
            this.shopService.getMyServiceById(token, idPrestion).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * @description Shop Provider : Retrieve list of hairdresser for contractor - SoulnBeauty
   * @param {Object} barbershop
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getBarberShopServices(barbershop: Object, refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          if (token) {
            // Reload data even if it is cached
            if (refreshCache) {
              this.shopService.clearBarberShopServicesCache(); // reinitialize the observable
            }
            this.shopService.getBarberShopService(barbershop, token).subscribe((result) => {
              // call complete if you want to close this stream (like a promise)
              // push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

/**
   * @description Shop Provider : update new hairdresser - SoulnBeauty
   * @param {Object} hairdresser
   * @returns {Observable<any>}
   */
  updateBarberShopServices(hairdresser: Object): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          if (token) {
            this.shopService.updateBarberShopService(hairdresser, token).subscribe((result) => {
              // call complete if you want to close this stream (like a promise)
              // push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * @description Shop Provider : add new hairdresser - SoulnBeauty
   * @param {Object} hairdresser
   * @returns {Observable<any>}
   */
  addBarberShopServices(hairdresser: Object): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          if (token) {
            this.shopService.addBarberShopService(hairdresser, token).subscribe((result) => {
              // call complete if you want to close this stream (like a promise)
              // push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * @description Shop Provider : remove hairdresser - SoulnBeauty
   * @param {Object} hairdresser
   * @returns {Observable<any>}
   */
  removeBarberShopService(hairdresser: Object): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          if (token) {
            this.shopService.removeBarberShopService(hairdresser, token).subscribe((result) => {
              // Reload data even if it is cached
              if (result.succes) {
                this.shopService.clearBarberShopServicesCache(); // reinitialize the observable
              }
              // call complete if you want to close this stream (like a promise)
              // push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Shop Provider : Retrieve list of category services SoulnBeauty
   * @param {any} prestationType
   * @returns {Observable<any>}
   */
  getPrestationByType(prestationType: any): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {

        this.prestationTypeService.getPrestationServicesByType(prestationType,token).subscribe((result) => {
          // call complete if you want to close this stream (like a promise)
          // push into subject
          console.log(result);
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error('Une erreur est survenue lors du chargement des données' || err);
        })})
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

}
