import { Injectable } from '@angular/core';
import {Storage} from "@ionic/storage";
import { Observable } from 'rxjs';
import { Events } from 'src/app/common/events';
import { ContractorShopService } from 'src/app/services/prestataire/contractor.shop.service';
import {ConnectivityProvider} from "../connectivity/connectivity";

/*
  Generated class for the OrderContractorProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class OrderContractorProvider {
  public notificationIsWaiting = false;
  public notificationData;
  constructor(private storage: Storage,
              private connectivityService: ConnectivityProvider,
              private ordersService: ContractorShopService,
              private events: Events) {
  }

  /**
   * Provider : Retrieve list of user orders services SoulnBeauty
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getMyReservations(action: boolean = false,limit): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        // Load token if exists
        this.storage.get('token').then((token) => {
          // if token exists
          if (token) {
            // Reload data even if it is cached
            // if (refreshCache) {
            //   this.ordersService.clearReservationCache(); // reinitialize the observable
            // }
            this.ordersService.getReservation(token,action,limit).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }


    /**
   * 
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getMyReservationById(id): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        // Load token if exists
        this.storage.get('token').then((token) => {
          // if token exists
          if (token) {

            this.notificationIsWaiting = false;
            // Reload data even if it is cached
            // if (refreshCache) {
            //   this.ordersService.clearReservationCache(); // reinitialize the observable
            // }
            this.ordersService.getReservationById(token,id).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            this.notificationIsWaiting = true;
            console.log('no token')
          }
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }

  }


      /**
   * 
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getMyReservationByIdClient(field): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        // Load token if exists
        this.storage.get('token').then((token) => {
          // if token exists
          if (token) {
            // Reload data even if it is cached
            // if (refreshCache) {
            //   this.ordersService.clearReservationCache(); // reinitialize the observable
            // }
            this.ordersService.getReservationByIdClient(token,field).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }

  }

     /**
   * 
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getDashboard(field): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        // Load token if exists
        this.storage.get('token').then((token) => {
          // if token exists
          if (token) {
            // Reload data even if it is cached
            // if (refreshCache) {
            //   this.ordersService.clearReservationCache(); // reinitialize the observable
            // }
            this.ordersService.getDashboard(token,field).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }

  }

  /**
   * Provider : Retrieve rib of a contractor
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getRib(refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        // Load token if exists
        this.storage.get('token').then((token) => {
          // if token exists
          if (token) {
            // Reload data even if it is cached
            if (refreshCache) {
              this.ordersService.clearReservationCache(); // reinitialize the observable
            }
            this.ordersService.getRibService(token).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  getMoneyRepository(refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        // Load token if exists
        this.storage.get('token').then((token) => {
          // if token exists
          if (token) {
            // Reload data even if it is cached
            if (refreshCache) {
              this.ordersService.clearMoneyCache(); // reinitialize the observable
            }
            this.ordersService.getMoneyService(token).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Provider : Update rib  for contractor
   * @param rib
   */
  postRib(rib) {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.ordersService.postRibService(rib, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error(`Une erreur est survenue lors de l'enregistrement` || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Provider : valid qrcode by contractor services SoulnBeauty
   * @param qrCode
   */
  validQrCodeByContractor(qrCode) {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.ordersService.validQrCodeService(qrCode, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error(`Une erreur est survenue lors de l'envoi du qrCode` || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

}
