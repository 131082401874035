import { Injectable } from "@angular/core";
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  /**
   * If using, define drivers here: await this.storage.defineDriver(...)
   */
  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    this._storage?.set(key, value);
  }

   // Create and expose methods that users of this service can
  // call, for example:
  public get(name) {
    return this._storage?.get(name);
  }
}
