/**
 * Created by Bugatti on 13/10/2017.
 */
import {Injectable} from '@angular/core';
import {BaseService} from "../base.service";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import { IUser } from 'src/app/interfaces/IUser';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { Auth } from 'src/app/class/auth';

@Injectable({
  providedIn:'root'
})
export class RegisterService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  /**
   *
   * @returns {string}
   */
  getEntityName() {
    return 'singUp';
  }

  /**
   * Inscription à l'application via API
   * @param user
   * @returns {Observable<R|T>}
   */
  postSignUp(user: IUser): Observable<Auth> {
    return this.http.post(this.getEntityUrl(), this.serialize(user), {headers: this.headers})
      .pipe(
        map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount() // used to keep the observable alive for as long as there are subscribers.
  
    )
  }

  /**
   * Retourne l'url de l'api
   * @returns {string}
   */
  protected getEntityUrl() {
    return this.baseUrl + this.getEntityName();
  }
}