import {Component, Input} from '@angular/core';

/**
 * Generated class for the LoaderSpinnerComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'loader-spinner',
  templateUrl: 'loader-spinner.html'
})
export class LoaderSpinnerComponent {

  @Input('showSplash') showSplash;

  constructor() {
  }

}
