import { Injectable } from '@angular/core';

export class Category {
  id: string;
  title: string;
  image: string;
}

export class Brand {
  id: string;
  title: string;
  logo: string;
}

export class Product {
  id: string;
  name: string;
  price: number;
  discount: number = 0;
  image: string;
  images?: string[];
  descriptions: string[];
  categories: Category[];
  brands?: Brand[];
  love?: boolean = false;
  status?: string;

  constructor() {
    this.categories = new Array<Category>();
    this.descriptions = new Array<string>();
    this.brands = new Array<Brand>();
  }
}

export class Address {
  firstname: string;
  lastname: string;
  address: string;
  phone: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
}

export interface WishProduct {
  product: Product;
  color?: string;
  size?: string;
}

export interface CartProduct extends WishProduct {
  quantity: number;
}

export class Order {
  id: string;
  date: Date;
  status: string;
}

@Injectable({
  providedIn:'root'
})
export class Cart {
  products: Array<CartProduct>;
  deliveryType: string;
  delivery: number;
  promotion: number = 0;
  private static instance: Cart = null;
  static isCreating: boolean = false;

  // Singleton
  constructor() {
    if (!Cart.isCreating) {
      throw new Error(`You can't call new in Singleton instance!`)
    } else {
      this.products = new Array<CartProduct>();
      this.initialize();
    }
  }

  private initialize() {
    let db = Database.getInstance();
    let products = db.allProduct();

    this.products.push({ product: products[0], quantity: 2, color: 'Green', size: 'M' })
    this.products.push({ product: products[1], quantity: 1, color: 'Pink', size: 'L' })
  }

  static getInstance() {
    console.log('Cart Provider');
    if (Cart.instance === null) {
      Cart.isCreating = true;
      Cart.instance = new Cart();
      Cart.isCreating = false;
    }
    return Cart.instance;
  }

  clear() {
    this.products = new Array<CartProduct>();
    this.deliveryType = ''
    this.delivery = 0;
    this.promotion = 0;
  }

  count(): number {
    let sum: number = 0;
    this.products.forEach(product => {
      sum = parseInt(sum.toString()) + parseInt(product.quantity.toString());
    });
    return sum;
  }

  total(): number {
    let sum: number = 0;
    this.products.forEach(item => {
      sum = parseInt(sum.toString()) + (parseInt(item.quantity.toString()) * (item.product.price - item.product.discount));
    });

    return sum;
  }

  promoTotal(): number {
    let sum: number = 0;
    this.products.forEach(item => {
      sum = parseInt(sum.toString()) + (parseInt(item.quantity.toString()) * (item.product.price - item.product.discount));
    });
    sum = parseInt(sum.toString()) - parseInt(this.promotion.toString());
    return sum;
  }
}
/*
  Generated class for the Database provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable({
  providedIn:'root'
})
export class Database {
  brands: Brand[];
  categories: Category[];
  products: Product[];
  addresses: Address[];
  wishproducts: WishProduct[];
  orders: Order[];
  filterTypes: any[];

  cities: string[];
  states: string[];
  countries: string[];
  zipcodes: string[];
  private static instance: Database = null;
  static isCreating: boolean = false;

  // Singleton
  constructor() {
    if (!Database.isCreating) {
      throw new Error(`You can't call new in Singleton instance!`)
    } else {
      this.brands = new Array<Brand>();
      this.categories = new Array<Category>();
      this.products = new Array<Product>();
      this.addresses = new Array<Address>();
      this.wishproducts = new Array<WishProduct>();
      this.orders = new Array<Order>();
      this.filterTypes = new Array<any>();

      this.cities = new Array<string>();
      this.states = new Array<string>();
      this.countries = new Array<string>();
      this.zipcodes = new Array<string>();
      this.initialize();
    }
  }

  static getInstance() {
    console.log('Database Provider');
    if (Database.instance === null) {
      Database.isCreating = true;
      Database.instance = new Database();
      Database.isCreating = false;
    }
    return Database.instance;
  }

  private initialize() {
    console.log('Initialize Database');
    this.countries.push('USA');
    this.states = [
      'New York',
      'California',
      'Indiana',
      'Washington',
    ];
    this.cities = [
      'New York',
      'Los Angeles',
      'San Diego',
      'Seattle',
      'Indianapolis',
      'Oakland'
    ];
    this.zipcodes = [
      '100000',
      '200000',
      '300000'
    ];
    this.addresses = [
      {
        firstname: 'John',
        lastname: 'Smith',
        address: '701, Block -  B, Siddhi Vinayak Tower',
        phone: '+91 1234 5678 99',
        city: this.cities[1],
        state: this.states[1],
        country: this.countries[0],
        zipcode: '100000'
      },
      {
        firstname: 'Vernon',
        lastname: 'Martin',
        address: '925 Buddy Motorway, New Street',
        phone: '+91 1234 5678 99',
        city: this.cities[0],
        state: this.states[0],
        country: this.countries[0],
        zipcode: '200000'
      },
    ];
    let now = new Date();
    let day = 24 * 60 * 60 * 1000;
    this.orders = [
      {
        id: 'SC' + (new Date(now.getTime() - 2 * day)).getTime().toString(),
        date: new Date(now.getTime() - 2 * day),
        status: 'Dispatched'
      },
      {
        id: 'SC' + (new Date(now.getTime() - 3 * day)).getTime().toString(),
        date: new Date(now.getTime() - 3 * day),
        status: 'On Way'
      },
      {
        id: 'SC' + (new Date(now.getTime() - 15 * day)).getTime().toString(),
        date: new Date(now.getTime() - 15 * day),
        status: 'Delivered'
      },
    ];

    this.brands = [
      {
        id: 'B0001',
        title: '3CE',
        logo: ''
      },
      {
        id: 'B0002',
        title: 'Annayake',
        logo: ''
      },
      {
        id: 'B0003',
        title: 'Beauty UK',
        logo: ''
      },
      {
        id: 'B0004',
        title: 'Bobbi brown',
        logo: ''
      },
      {
        id: 'B0005',
        title: 'Bourjois',
        logo: ''
      },
      {
        id: 'B0006',
        title: 'Chanel',
        logo: ''
      },
      {
        id: 'B0007',
        title: 'Christian Louboutin',
        logo: ''
      },
      {
        id: 'B0008',
        title: 'Cezanne',
        logo: ''
      },
      {
        id: 'B0009',
        title: 'Dior',
        logo: ''
      },
      {
        id: 'B0010',
        title: 'Estee Lauder',
        logo: ''
      },
      {
        id: 'B0011',
        title: 'Enprani',
        logo: ''
      },
      {
        id: 'B0012',
        title: 'Farmasi',
        logo: ''
      },
      {
        id: 'B0013',
        title: 'Guerlain',
        logo: ''
      },
      {
        id: 'B0014',
        title: 'Glossip Milano',
        logo: ''
      },
      {
        id: 'B0015',
        title: 'Histomer Italy',
        logo: ''
      },
      {
        id: 'B0016',
        title: 'Lancome',
        logo: ''
      },
      {
        id: 'B0017',
        title: `L'Oreal`,
        logo: ''
      },
      {
        id: 'B0018',
        title: 'Maybeline',
        logo: ''
      }
    ]

    this.categories = [
      {
        id: 'A0001',
        image: 'assets/img/all.png',
        title: 'Nos produits'
      },
      {
        id: 'A0002',
        image: 'assets/img/lipstick.png',
        title: 'Coiffure'
      },
      {
        id: 'A0003',
        image: 'assets/img/faces.png',
        title: 'Maquillage'
      },
      {
        id: 'A0004',
        image: 'assets/img/brush.png',
        title: 'Evénément'
      },
      {
        id: 'A0005',
        image: 'assets/img/palettes.png',
        title: 'Ongles'
      },
      {
        id: 'A0006',
        image: 'assets/img/eyes.png',
        title: 'Création'
      }
      // {
      //   id: 'A0007',
      //   image: 'assets/img/nails.png',
      //   title: 'Nails'
      // },
      // {
      //   id: 'A0008',
      //   image: 'assets/img/bodycare.png',
      //   title: 'Body Care'
      // },
    ];

    this.products = [
      // Western wear
      {
        id: '0001',
        name: `Makeup Geek Brush - Dual-Ended Brow Brush`,
        price: 100,
        discount: 5,
        image: 'url(assets/img/brush/makeup_geek_brush.jpg)',
        descriptions: ['When it comes to creating arches, defining tails, and adding fullness to your brows, the Dual-Ended Brow Brush packs some serious brow-shaping power. Use the slightly stiff angled brush tip with powders and pomades to draw thin lines that mimic the look of real hair. The spoolie end is a useful bonus tool to brush your eyebrows into place and ensure your brow product is evenly dispersed. Whether you’re a bold brow enthusiast or more of a natural brow fan, this do-it-all brush is a must-have tool for any collection.'],
        categories: [this.categories[3]],
        love: true,
        status: 'in'
      },
      {
        id: '0002',
        name: `Advanced Night Repair Eye' Synchronized Recovery Complex II by ESTÉE LAUDER`,
        price: 160,
        discount: 15,
        image: 'url(assets/img/eyes/Advanced_Night_Repair_Eye.jpg)',
        descriptions: ['What it is: An anti-aging eye cream with a rich, silky, gel texture proven to reduce the look of every key visible sign of eye aging, including fine lines, wrinkles, puffiness, dark circles, dryness and uneven skin tone.', `Who it's for: All skin types.`, `What it does: It's tested and proven to deliver significant clinical improvement to your eyes for a youthful, vibrant look, leaving your entire eye area cushioned and comfortable. Exclusive ChornoluxCB™ Technology maximizes the power of night to re-ignite the light that can fade due to fatigue, pollution and age.`],
        categories: [this.categories[5]],
        love: true,
        status: 'out'
      },
      {
        id: '0003',
        name: `Estée Lauder - Revitalizing Supreme' Global Anti-Aging Eye Balm + FREE DELUXE ESTEE LAUDER LIP GLOSS (Seductive Honey)`,
        price: 200,
        discount: 5,
        image: 'url(assets/img/eyes/Global_Anti_Aging_Eye_Balm.jpg)',
        descriptions: [`Revitalizing Supreme Global Anti-Aging Eye Balm is a high-performance, multi-action eye balm that has the power to respond skin's changing needs. It helps measurably reduce the look of multiple signs of aging around your eyes, including lines, wrinkles and crepiness. Potent IntuiGen Technology™ helps reactivate skin's ability to look younger while its velvety texture instantly nourishes and revitalizes as it creates a more youthful look. With use over time, your eyes look firmer, brighter and more beautiful. For an added boost, it can also be used as a weekly eye mask.`],
        categories: [this.categories[5]],
        love: false,
        status: 'out'
      },
      {
        id: '0004',
        name: `TARTE - pack your bags 911 undereye rescue patches `,
        price: 220,
        discount: 5,
        image: 'url(assets/img/eyes/undereye_rescue_patches.jpg)',
        descriptions: [`Send those bags packing with these powerful rescue patches infused with the proprietary Rainforest of the Sea™ antioxidant complex. With each refreshing, single-use application, these gentle, restorative pads soothe and hydrate the delicate undereye area, while fighting the look of puffiness, dark circles and crow’s feet. With a smooth, contoured pad shape and stay-put, non-adhesive design, each flexible, hypoallergenic gel patch is water-infused to quench skin upon contact.`],
        categories: [this.categories[5]],
        love: false,
        status: 'out'
      },
      {
        id: '0005',
        name: `TARTE - Amazonian butter lipstick in park ave princess`,
        price: 180,
        discount: 5,
        image: 'url(assets/img/lipsticks/lipstick_in_park_ave_princess.jpg)',
        descriptions: [`Like a drink of water for your lips, tarte’s best-selling Amazonian butter lipstick is now available in everyone’s favorite park ave princess™ shade for a radiant pop of natural-looking nude bronze as it nourishes and hydrates your precious pout.`],
        categories: [this.categories[1]],
        love: false,
        status: 'out'
      },
      {
        id: '0006',
        name: `Fresh - 'Beauty Rituals' Set`,
        price: 595,
        discount: 5,
        image: 'url(assets/img/bodycare/skincare_Beauty_Rituals.jpg)',
        descriptions: [`Good skin starts with fresh skin. This collection of Fresh products treats your skin to the very best so your complexion is left cleansed and hydrated.`, `Set includes:`,
          `- Soy Face Cleanser (1.7 oz.): a gentle cleanser for all skin types that removes impurities and makeup (including mascara) without stripping away essential moisture.`,
          `- Rose Face Mask (1 oz.): a hydrating and toning mask infused with real rose petals that promotes radiance and suppleness.`,
          `- Lotus Youth Preserve Face Cream (0.5 oz.): a universal daily moisturizer specifically designed to minimize and prevent visible signs of aging, deliver intense hydration and boost radiance.`,
          `- Sugar Lip Treatment Advanced Therapy (0.15 oz.): an ultra-nourishing lip treatment proven to significantly boost moisture, enhance volume and improve the definition of the lip area.`],
        categories: [this.categories[7]],
        love: false,
        status: 'out'
      },
      {
        id: '0007',
        name: `LANCOME GIFT SET`,
        price: 300,
        discount: 5,
        image: 'url(assets/img/bodycare/skincare_LANCOME_GIFT_SET.jpg)',
        descriptions: [`Receive a cosmetics bag with the foll:`,
          `1) Hynôse Drama Instant Full Volume Mascara (0.14 oz.)`,
          `2) Bi-Facil Double-Action Eye Makeup Remover (1.7 oz.)`,
          `3) La Base Pro Perfecting Makeup Primer (0.23 oz.)`,
          `4) Blush Subtil Palette in 323 Rose Flush (0.07 oz.)`,
          `5) Rénergie Lift Multi Action Moisturizer Cream SPF 15 for All Skin Types (0.5 oz.) `,
          `6) Crème Mousse Confort Creamy Foaming Cleanser (2.0 oz.)`],
        categories: [this.categories[7]],
        love: false,
        status: 'out'
      },
      {
        id: '0008',
        name: `COVER FX - Custom Enhancer Drops`,
        price: 410,
        discount: 5,
        image: 'url(assets/img/palettes/palettes_custom_enhancer_drops.jpg)',
        descriptions: [`Availavle shades -`,
          `- HALO- holographic, multi-dimensional`,
          `- MOONLIGHT - imparts a soft, silver glow`,
          `- SUNLIGHT- imparts a soft, golden glow`,
          `- CANDLELIGHT - illuminator shimmering golden bronze`,
          `- BLOSSOM - soft, pearlescent pink`],
        categories: [this.categories[4]],
        love: false,
        status: 'out'
      },
      {
        id: '0009',
        name: `Moisture Surge Hydrating Supercharged Concentrate BY CLINIQUE`,
        price: 135,
        discount: 5,
        image: 'url(assets/img/faces/faces_Moisture_Surge_Hydrating_Supercharged.jpg)',
        descriptions: [`What it is: An antioxidant-infused water-gel that's ultralight yet delivers a 179% moisture boost for instantly plumper, smoother skin.`,
          `Who it's for: All skin types.`,
          `What it does: This supercharged hydrator boosts skin's moisture reservoir for a full 24 hours. Liquid Sphere Technology combines water-binding ingredients with encapsulated antioxidants to help break the cycle of dryness and environmental stress that can lead to premature aging.`,
          `How to use: Apply morning and night on clean skin.`],
        categories: [this.categories[2]],
        love: false,
        status: 'out'
      },
      {
        id: '0010',
        name: `Fresh Pressed Daily Booster with Pure Vitamin C 10% by CLINIQUE`,
        price: 215,
        discount: 5,
        image: 'url(assets/img/faces/face_Fresh_Pressed_Daily_Booster.jpg)',
        descriptions: [`What it is: A unique, de-aging booster that harnesses the full power of pure, fresh vitamin C at a high concentration to brighten, even and retexturize skin.`,
          `Who it's for: All skin types.`,
          `What it does: Just like your morning fresh-pressed juice or vitamin, this booster helps you stay healthy on the inside. The super-potent formula was designed as a daily supplement to visibly rejuvenate skin from the outside, and is a simple, seamless way to reinvigorate your de-aging routine without changing a thing. Skin looks brighter immediately and, over time, age spots and the look of lines and wrinkles are reduced.`,
          `How to use: Leave the cap on. Remove the foil. Firmly press the inner bulb twice to release vitamin C powder into the soothing emulsion. Shake for 15 seconds before each use. Remove the cap. Mix two drops with your moisturizer in the morning and night to power up your regular routine. Avoid the eye area. For full potency, use within seven days, then discard. Please note that once activated, the booster will retain full power for seven days but may darken due to normal vitamin C oxidatio`],
        categories: [this.categories[2]],
        love: false,
        status: 'out'
      },
      {
        id: '0011',
        name: `DIAMOND CRUSHERS- Iridescent Lip Topper that gets you LIT - cheap thrill (sunset/rose shift)`,
        price: 180,
        discount: 5,
        image: 'url(assets/img/lipsticks/lipstick_DIAMOND_CRUSHERS.jpg)',
        images: [
          `url(assets/img/lipsticks/lipstick_DIAMOND_CRUSHERS.jpg)`,
          `url(assets/img/lipsticks/lipstick_DIAMOND_CRUSHERS_2.jpg)`,
          `url(assets/img/lipsticks/lipstick_DIAMOND_CRUSHERS_3.jpg)`
        ],
        descriptions: [`What it does: Glitters up your life. Enhances liquid mattes. Makes you look like f*cking amazing!`,
          `What it won't do: Run, feel gritty or drying. Ruin your liquid matte lipstick.`,
          `THINGS TO TRY:`,
          `- Pop over your bare lips. Get LIT.`,
          `- Wear over your favorite liquid lipstick to take your game to the next level! (Smooth over with finger if necessary.)`,
          `- Top with clear gloss - because why not!`,
          `- Wear as mermaid highlighter on cheeks, collar bones and under the brow.`,
          `- Take lots & lots of selfies!!!`],
        categories: [this.categories[1]],
        love: false,
        status: 'out'
      },
      {
        id: '0012',
        name: `KOKO KOLLECTION FACE PALETTE`,
        price: 425,
        discount: 5,
        image: 'url(assets/img/palettes/KOKO_KOLLECTION_FACE_PALETTE.jpg)',
        descriptions: [`The Koko Kollection Pressed Powder Face Palette contains:`,
          `1 Satin Pressed Powder Blush (net weight / pods net 1 X 8.5 grams / 1 X 0.30 oz)`,
          `2 Pressed Illuminating Powder (net weight / pods net 2 X 4.5 grams / 1 X 0.16 oz)`,
          `1 Matte Pressed Powder Bronzer (net weight / pods net 1 X 7.5 grams / 1 X 0.26 oz)`,
          `The Koko Kollection Face Palette contains 1 Satin Powder Blush, 2 Pressed Illuminating Powders and 1 Matte Pressed Powder Bronzer:`,
          `- Rodeo Drive is a warm pink satin.`,
          `- Troop Beverly Hills is a shimmering soft gold.`,
          `- 90210 is a shimmering golden peach.`,
          `- Hollywood Blvd is a satin medium bronze.`],
        categories: [this.categories[4]],
        love: false,
        status: 'out'
      },
      {
        id: '0013',
        name: `FULL-SIZE 4PC HOLIDAY KIT | MATTE LIQUID LIPSTICKS & GLOSS`,
        price: 425,
        discount: 5,
        image: 'url(assets/img/lipsticks/Lipstick_FULL_SIZE_4PC_HOLIDAY_KIT.jpg)',
        descriptions: [`Contains: 2 x Matte Liquid Lipstick (2 X 0.11 fl oz./oz. liq / 3.25 ml) 2 x Gloss (0.11 fl oz./oz. liq / 3.00ml)`,
          `The #KylieCosmetics Holiday Kit is the perfect collection of all your favorite holiday shades in one. Each kit comes with 2 matte liquid lipsticks and 2 gloss shades. These special shades are infused with diamond powder and packaged in limited edition silver packaging for the holiday season.`,
          `Each holiday kit contains the following shades: `,
          `- Cupid (coppery rose gold)`,
          `- Angel (mauve-y pink)`,
          `- Blitzen (deepened raspberry-plum; a warmer version of Spice)`,
          `- Noël (true copper)`],
        categories: [this.categories[1]],
        love: false,
        status: 'out'
      },
      {
        id: '0014',
        name: `OKURRR | MATTE LIQUID LIPSTICK`,
        price: 195,
        discount: 5,
        image: 'url(assets/img/lipsticks/MATTE_LIQUID_LIPSTICK.jpg)',
        descriptions: [`Contains:`,
          `1 Matte Liquid Lipstick (0.11 fl oz./oz. liq / 3.25 ml)`,
          `The Matte Liquid Lipstick has high intensity pigment for an instant bold matte lip. The extremely long wearing lipstick contains moisturizing ingredients for a comfortable, emollient and silky feel that does not dry your lips out`],
        categories: [this.categories[1]],
        love: false,
        status: 'out'
      },
      {
        id: '0015',
        name: `Firmarine™ Hydrogel Mask by ERNO LASZLO`,
        price: 150,
        discount: 5,
        image: 'url(assets/img/faces/face_Hydrogel_Mask_by_ERNO_LASZLO.jpg)',
        descriptions: [`What it is: Erno Laszlo Firmarine Hydrogel Mask feels feather-light on your skin and won't slip off, mimicking skin's own texture to deliver lifting, firming and plumping hydration deep below your skin's surface. Remove it to reveal newly supple contours, better elasticity and fewer signs of wrinkles and sagging, and return your skin to youth.`,
          `Who it's for: Suitable for all skin types.`,
          `What it does: The nonirritating treatment is powered by lifting marine actives and intensely hydrating oils, immediately infusing skin with rejuvenating and firming moisture. Grapefruit extract evens out skin tone and is loaded with skin-protective antioxidants and also hydrates, plumps and smoothes the look of lines; argan oil, derived from seeds, has high levels of vitamin E to neutralize free radicals and restore nourishing lipids to the skin's surface; and seaweed extract helps the skin maintain its moisture balance as it clears out toxins.`,
          `How to use: Use weekly. Remove the clear lining from the bottom of the mask, then align and apply on a dry, clean face. Smooth out the edges firmly, adjusting to the contours of your face. After 20 minutes, remove the mask and massage remaining essence onto your face wherever extra treatment is needed.`],
        categories: [this.categories[2]],
        love: false,
        status: 'out'
      },
      {
        id: '0016',
        name: `NeuLash by Skin Research Laboratories Active Eyelash Technology`,
        price: 500,
        discount: 5,
        image: 'url(assets/img/eyes/Active_Eyelash.jpg)',
        descriptions: [`This innovative eyelash enhancement serum promotes healthy, natural lashes and brows with amazing results in just 4 weeks with a simple once a day application. It shields against all the aggressors and helps improve the overall condition and strength of eyelashes and eyebrows.`,
          `NeuLash delivers essential proteins, vitamins, moisturizing and rejuvenating ingredients that help replenish and strengthen lashes, while adding shine and elasticity. Each strand is nourished and the lash line energized with incredible results that in turn thin, brittle or short lashes or brows into thicker, healthier-looking lashes and brows in just 30 days. 6 milliliters.`,
          `About neuLash:`,
          `In 2008, CEO Richard Carieri and Skin Research Laboratories, with more than 35 years of experience, created neuLash Active Eyelash Technology. The eyelash-enhancement serum combines bioengineered polypeptides with beauty-enhancing ingredients to promote luxurious and fuller-looking eyelashes. The research and development team behind the label included chemists, medical advisors, and scientific experts.`],
        categories: [this.categories[5]],
        love: false,
        status: 'out'
      },
      {
        id: '0017',
        name: `Lancôme- Advanced GÉNIFIQUE Youth Activating Concentrate`,
        price: 120,
        discount: 5,
        image: 'url(assets/img/bodycare/skincare_Youth_Activating_Concentrate.jpg)',
        descriptions: [`A serum so powerful it creates more radiant and smoother skin that you can see and feel. This innovative formula featuring Génifique’s powerful complex with added biotechnology-derived ingredient is incredible to the touch, and acts on 10 key signs of youth including texture, resiliency, elasticity, firmness, sagginess, fine lines, wrinkles, radiance, clarity, and skintone.`],
        categories: [this.categories[7]],
        love: false,
        status: 'out'
      },
      {
        id: '0018',
        name: `PAULA'S CHOICE 'Skin Perfecting' 2% BHA Liquid`,
        price: 75,
        discount: 5,
        image: 'url(assets/img/bodycare/Skin_Perfecting.jpg)',
        descriptions: [`Rapidly exfoliate your skin's surface with Paula's Choice Skin Perfecting 2% BHA Liquid, which also targets inside your pores, reshaping the pore lining, unclogging and improving skin cell turnover rate. The result is a dramatic improvement in the skin's appearance, feel and function, which leads to healthier skin. Research indicates that BHA (beta hydroxy acid) increases collagen production and drastically smoothes the surface of your skin. The liquid also has antibacterial properties that attack blemish-causing bacteria and is an ideal choice for stubborn clogged pores or blackheads.`],
        categories: [this.categories[7]],
        love: false,
        status: 'out'
      },
      {
        id: '0019',
        name: `AMOREPACIFIC MOISTURE BOUND Refreshing Hydra-Gel Oil-Free`,
        price: 100,
        discount: 5,
        image: 'url(assets/img/faces/face_AMOREPACIFIC.jpg)',
        descriptions: [`Which skin type is it good for?`,
          `✔ Normal`,
          `✔ Combination`,
          `✔ Dry`,
          `✔ Sensitive`,
          `What it is:`,
          `An light gel daily moisturizer with the power of bamboo sap, ginger-root extract, and 5-Hydra complex™.`,
          `Solutions for:`,
          `- Dryness`,
          `- Fine lines and wrinkles`,
          `- Redness and irritation`],
        categories: [this.categories[2]],
        love: false,
        status: 'out'
      },
      {
        id: '0020',
        name: `Naked: The Perfect 3Some Vault`,
        price: 1300,
        discount: 5,
        image: 'url(assets/img/palettes/palettes_naked.jpg)',
        descriptions: [`Each NAKED PALETTE is sold at $480.00`,
          `What it is: A trio of UD's coveted neutral palettes.`,
          `What it does: With a range of shades and finishes this huge, you’ll never run out of options. Go bronze with Naked (the palette that started it all). Channel a more taupe vibe with Naked2. Or recreate that golden-hour light with rose-hued Naked3. Each palette comes loaded with a full-size mirror and a double-ended brush, so you can get Naked whenever the urge strikes. This set comes in a gorgeous, collectible case with a removable inner tray.`,
          `Vault includes:`,
          `- Naked: featuring Virgin (cool pale beige satin), Sin (champagne shimmer), Naked (nude matte), Sidecar (beige shimmer w/silver micro-glitter), Buck (fawn brown matte), Half Baked (golden bronze shimmer), Smog (deep coppery bronze shimmer), Darkhorse (deep mocha shimmer), Toasted (antique copper penny shimmer), Hustle (plum-brown satin), Creep (onyx shimmer w/gold sparkle) and Gunmetal (dark metallic gray shimmer w/silver micro-glitter).`,
          `- Naked2: featuring Foxy (cream matte), Half Baked (golden bronze shimmer), Bootycall (cork shimmer), Chopper (copper shimmer w/silver micro-glitter), Tease (creamy pale brown matte), Snakebite (dark bronze shimmer), Suspect (pale golden beige shimmer), Pistol (light greyish brown shimmer), Verve (oyster shimmer), YDK (cool bronze shimmer), Busted (deep brown shimmer) and Blackout (blackest black matte).`,
          `- Naked3: featuring Strange (pale neutral pink matte-satin), Dust (pale metallic pink shimmer w/iridescent micro-glitter), Burnout (light pinky-peach satin), Limit (light dusty rose matte), Buzz (metallic rose shimmer w/silver micro-glitter), Trick (light metallic pinky-copper shimmer w/tonal micro-sparkle), Nooner (medium pinky-brown matte), Liar (medium metallic mauve shimmer), Factory (pinky-brown satin), Mugshot (metallic taupe shimmer w/slight pink shift), Darkside (deep taupe-mauve satin) and Darkheart (smoky black matte w/rosy red micro-sparkle).`],
        categories: [this.categories[4]],
        love: false,
        status: 'out'
      },
      {
        id: '0021',
        name: `Short and Sweet // Mini Size Kit`,
        price: 190,
        discount: 5,
        image: 'url(assets/img/lipsticks/lipstick_Short_and_Sweet.png)',
        descriptions: [`Love Muffin: Ultra Satin Lip; pastel baby pink`,
          `Sugar Lips: Ultra Glossy Lip; golden pink with gold, silver, copper and hot pink glitter`,
          `Schnookums: Ultra Satin Lip; mid- tone warm rose`,
          `Pooky Bear: Ultra Matte Lip; true coral`,
          `Honey Pie: Ultra Matte Lip; bright pinky red`],
        categories: [this.categories[1]],
        love: false,
        status: 'out'
      },
      {
        id: '0022',
        name: `11 pc Pink-A-Dot Brush Set`,
        price: 240,
        discount: 5,
        image: 'url(assets/img/brush/brush_dot_set.jpg)',
        descriptions: [`This super-soft 11 piece synthetic brush set includes a great variety of both complexion and eye brushes and is cruelty free! The bonus Deluxe Fan Brush included is a great brush for bronzing the chest or body. Featuring quirky and cute polka dotted brush handles, this set comes wrapped inside its own adorable matching cylindrical hard case that snaps up for quick travel and looks stylish on a makeup station or counter top!`],
        categories: [this.categories[3]],
        love: false,
        status: 'out'
      },
      {
        id: '0023',
        name: `BH - Eye Essential - 7 Piece Brush Set`,
        price: 150,
        discount: 5,
        image: 'url(assets/img/brush/brush_Eye_Essential_7_Piece_Brush_Set.jpg)',
        descriptions: [`Line, define, shade, and blend shadow with our versatile seven-piece Eye Essential Brush Set. A complete wardrobe of professional-quality synthetic and natural-hair brushes, it offers endless options for creating any eye look that suits your mood, from soft and subtle to bold and bright. You’ll be the belle of the ball in the blink of an eye.`,
          `Features & Benefits`,
          `Natural-Hair & Synthetic Brushes`,
          `Professional Quality`,
          `Easy to Clean`,
          `Versatile Use`],
        categories: [this.categories[3]],
        love: false,
        status: 'out'
      },
      {
        id: '0024',
        name: `Ariel Gordon Candy Crush`,
        price: 16,
        discount: 5,
        image: 'url(assets/img/nails/ariel-gordon.jpg)',
        descriptions: [`I'm saving up for one of Ariel Gordon's rainbow Candy Crush rings to match to my current obsession -- her glitter bomb Candy Crush lacquer. — Emily Dougherty, Beauty & Fitness Director Ariel Gordon Candy Crush`],
        categories: [this.categories[6]],
        love: false,
        status: 'out'
      },
      {
        id: '0025',
        name: `CND Shellac in Cake Pop`,
        price: 20,
        discount: 5,
        image: 'url(assets/img/nails/cnd-cake-pop.jpg)',
        descriptions: [`For a few summers I've worn a white manicure exclusively, but this season I'm ready to move on to this saccharine sweet nail polish color. I not only love the way it looks against my skin tone, but also feel like it's a good counter to my tomboy-ish style and sharp haircut. That last part may be in my head, but that's actually my reasoning. — Nikki Ogunnaike, ELLE.com Senior Fashion Editor`],
        categories: [this.categories[6]],
        love: false,
        status: 'out'
      }, {
        id: '0026',
        name: `Floss Gloss in Night Palm`,
        price: 8,
        discount: 5,
        image: 'url(assets/img/nails/floss-gloss-nightpalm_logo.jpg)',
        descriptions: [`This palm tree colored polish works like color correcting concealer for my reddish hands. Summer polish doesn't have to be bright.— Julie Schott, ELLE.com Beauty Director.`],
        categories: [this.categories[6]],
        love: false,
        status: 'out'
      }, {
        id: '0027',
        name: `CND Vinylux in Electric Orange`,
        price: 10.5,
        discount: 5,
        image: 'url(assets/img/nails/cnd-vinylux-in-electric-orange.jpg)',
        descriptions: [`I'm fair year-round, but get a little more color in the summer (including via self-tanner). As such, I wear bright colors that feel at-home in the warmer weather and also require something other than alabaster skin to work. Bright orange is cheerful, but different than the more expected pinks and reds. Plus, this CND formula is more durable than regular polish, making it last longer (a major pro always, but especially when it's summer and you want to be out and about, not cooped up in a salon chair). — Leah Melby-Clinton, ELLE.com Senior Editor of Branded Content`],
        categories: [this.categories[6]],
        love: false,
        status: 'out'
      }, {
        id: '0028',
        name: `Essie in California Coral`,
        price: 5,
        discount: 5,
        image: 'url(assets/img/nails/essie-california-coral.jpg)',
        descriptions: [`I like a bright fun coral for summer as my wardrobe gets lighter and more colorful. Essie remains my favorite nail polish brand because of the way the colors just easily go and stay on. So its peach-pink coral is my fave go-to to liven things up. (If I'm feeling really jazzy, I'll add a glitter top coat too. Summer is the time to be loud with your style). — Alyssa Bailey, ELLE.com Associate News Editor`],
        categories: [this.categories[6]],
        love: false,
        status: 'out'
      }, {
        id: '0029',
        name: `Essie in Really Red`,
        price: 9,
        discount: 5,
        image: 'url(assets/img/nails/essie.jpg)',
        descriptions: [`I'm a creature of habit when it comes to my nail color (there's really nothing worse than trying something new and then not recognizing your own hands for a whole week), so my nails usually look the same, no matter the season. But! If I'm looking to perk things up a bit, Essie's "Really Red" is a sunnier, spicier hue than my normal go-to. — Sally Holmes, ELLE.com Deputy Editor`],
        categories: [this.categories[6]],
        love: false,
        status: 'out'
      }, {
        id: '0030',
        name: `Riggs Nail Polish in Grace`,
        price: 18,
        discount: 5,
        image: 'url(assets/img/nails/jin-soon.jpg)',
        descriptions: [`My go-to nail color will always be vamp because I loved it in the '90s and never stopped. But for summer I like this oh so subtle grey – it doesn't feel harsh or dreary and will go with everything. — Leah Chernikoff, ELLE.com Director`],
        categories: [this.categories[6]],
        love: false,
        status: 'out'
      }, {
        id: '0031',
        name: `Nails Inc. Sweet Almond Nail Polish in Cornwall Gardens`,
        price: 15,
        discount: 5,
        image: 'url(assets/img/nails/nails-inc.jpg)',
        descriptions: [`There's never really just one color I stick to in summer, to be honest—it's my one concession to color given I'm usually still clad completely in black from head to toe. But I like this pastel shade. The purple hits the sweet spot between lilac and grey—definitely not your grandma's lavender. (No offence, grandma!) Is there such a thing as Millennial Lavender? I guess this is it. — Estelle Tang, ELLE.com Culture Editor`],
        categories: [this.categories[6]],
        love: false,
        status: 'out'
      }, {
        id: '0032',
        name: `RGB Cosmetics Nail Color in Cameo`,
        price: 9,
        discount: 5,
        image: 'url(assets/img/nails/rgb.jpg)',
        descriptions: [`RGB Cameo is my perfect nude—and let's me keep my "the barer the better" summer aesthetic uninterrupted. — Cotton Codinha Associate Beauty and Fitness Editor`],
        categories: [this.categories[6]],
        love: false,
        status: 'out'
      }, 
      /*{
        id: '00',
        name: ``,
        price: 0,
        discount: 5,
        image: 'assets/img/',
        descriptions: [``],
        categories: [this.categories[6]],
        love: true,
        status: 'out'
      }, {
        id: '00',
        name: ``,
        price: 0,
        discount: 5,
        image: 'assets/img/',
        descriptions: [``],
        categories: [this.categories[6]],
        love: true,
        status: 'out'
      }, {
        id: '00',
        name: ``,
        price: 0,
        discount: 5,
        image: 'assets/img/',
        descriptions: [``],
        categories: [this.categories[6]],
        love: true,
        status: 'out'
      },*/
    ];

    this.wishproducts = [
      {
        product: this.products[0],
        color: 'Green',
        size: 'M'
      },
      {
        product: this.products[1],
        color: 'Pink',
        size: 'L'
      }
    ]
  }

  allBrand(): Brand[] {
    return this.brands;
  }

  allCategory(): Category[] {
    return this.categories;
  }

  allSavedAdddress(): Address[] {
    this.addresses = this.addresses.sort((a, b) => { return a.firstname.charCodeAt(0) - b.firstname.charCodeAt(0) });
    return this.addresses;
  }

  allWishList(): WishProduct[] {
    return this.wishproducts;
  }

  allOrders(): Order[] {
    return this.orders;
  }

  removeWish(wish: WishProduct): void {
    var pos = -1;
    for (var i = 0; i < this.wishproducts.length; i++) {
      if (this.wishproducts[i] === wish) {
        pos = i;
      }
    }
    if (pos >= 0) {
      this.wishproducts.splice(pos, 1);
      wish.product.love = false;
    }
  }

  removeProductWish(prod: Product) {
    var pos = -1;
    for (var i = 0; i < this.wishproducts.length; i++) {
      if (this.wishproducts[i].product.id === prod.id) {
        pos = i;
      }
    }
    if (pos >= 0) {
      this.wishproducts.splice(pos, 1);
      prod.love = false;
    }
  }

  addWish(wish: WishProduct): void {
    this.wishproducts.push(wish);
  }

  allCities(): string[] {
    return this.cities;
  }

  allStates(): string[] {
    return this.states;
  }

  allCountries(): string[] {
    return this.countries;
  }

  allZipCodes(): string[] {
    return this.zipcodes;
  }

  removeSavedAddress(addr: Address): void {
    var pos = -1;
    for (var i = 0; i < this.addresses.length; i++) {
      if (this.addresses[i] === addr) {
        pos = i;
      }
    }
    if (pos >= 0) {
      this.addresses.splice(pos, 1);
    }
  }

  addSavedAddress(addr: Address): void {
    this.addresses.push(addr);
    this.addresses = this.addresses.sort((a, b) => { return a.firstname.charCodeAt(0) - b.firstname.charCodeAt(0) });
  }

  addOrder(order: Order) {
    this.orders.push(order);

    this.orders = this.orders.sort((a, b) => { return b.date.getTime() - a.date.getTime() });
  }

  allProduct(): Product[] {
    return this.products;
  }

  getMaxPrice(): number {
    let max = 0;
    
    this.products.forEach(product => {
      if(product.price > max) {
        max = product.price;
      }
    });

    return max;
  }

  categoryProducts(category: Category): Product[] {
    return this.products;
  }
}
