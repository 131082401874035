/**
 * Created by Bugatti on 06/10/2017.
 */
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {catchError, map, publishReplay, refCount} from "rxjs/operators";
import {CacheService} from "ionic-cache";
import {BaseService} from "../base.service";
import { Auth } from 'src/app/class/auth';


@Injectable({
  providedIn:'root'
})
export class ContractorService extends BaseService {


  private _userCoDetails: Observable<any> = null;
  private fields = ''; // '?fields=ID_PRESTATEUR:NOM_PRESTATAIRE:PRENON_PRESTATAIRE:DATE_DE_NAISSANCE_PRESTATAIRE:SEXE_PRESTATAIRE:DATE_INSCRIPTION_PRESTATAIRE:EMAIL_PRESTATAIRE:ADRESSE_PRESTATAIRE:TELEPHONE_PRESTATAIRE';
  private authKey = 'auth-offline';


  /**
   *
   * @param {HttpClient} http
   * @param {CacheService} cache
   */
  constructor(private http: HttpClient,
              private cache: CacheService) {
    super();
  }

  /**
   *
   * @returns {string}
   */
  getEntityName() {
    return 'signInPresta';
  }

  /**
   * Connexion à l'application via API
   * @param user
   * @returns {Observable<R|T>}
   */
  postSignIn(user: Object): Observable<Auth> {
    return this.http.post(this.getEntityUrl(), this.serialize(user), {headers: this.headers})
    .pipe(map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
  )
  }

  /**
   * Retourne les informations personnelles d'un prestataire connecté via le token
   * @returns {Observable<R|T>}
   */
  getContractorInfos(token,hasParameter?): Observable<Auth> {
    let headers = {'Jwt': token};
     if( hasParameter)
     {
       this.fields = hasParameter;
     }
      return this._userCoDetails = this.http.get(this.baseUrl + 'getPrestataireInfos' + this.fields, {headers: headers}).pipe(
        map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      )
    // Load with Group key (@returns Observable)
   // return this.cache.loadFromObservable(this.baseUrl + 'getPrestataireInfos' + this.fields, this._userCoDetails, this.authKey);
  }


  deleteAccountuser(token) {
    let headers = { 'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded' };
console.log(token)

    return this.http.post(`${this.baseUrl}Removepresta`,this.serialize(null), { headers: headers })
      .pipe(map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
      );
  }


  /**
   * Reload data even if it is cached
   * Load item from cache if it's in cache or load from origin observable
   */
  refreshCache() {
    this.cache.loadFromDelayedObservable(this.baseUrl + 'getPrestataireInfos' + this.fields, this._userCoDetails, this.authKey, 90, 'all');
    this._userCoDetails = null;
  }

  /**
   * Invalidate for a specific group key
   */
  invalidateCache() {
    this.cache.clearGroup(this.authKey);
    this._userCoDetails = null;
  }


  /**
   * Retourne l'url de l'api
   * @returns {string}
   */
  protected getEntityUrl() {
    return this.baseUrl + this.getEntityName();
  }

}