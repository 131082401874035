import { HttpClient } from "@angular/common/http";
import { Component, ViewChild, Injector, NgZone } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { Platform, ModalController, ToastController, AlertController, IonRouterOutlet, MenuController } from "@ionic/angular";
import { CacheService } from "ionic-cache";
import { Observable } from "rxjs";
import {Storage} from "@ionic/storage";
import { Events } from "./common/events";
import { LoginCoPage } from "./contractors/login-co/login-co";
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { IMenuItem } from "./interfaces/IMenuItem";
import { AuthContractorProvider } from "./providers/auth-contractor/auth-contractor";
import { AuthUserProvider } from "./providers/auth-user/auth-user";
import { StorageService } from "./services/storage.service";
import { Alert } from "selenium-webdriver";
import { filter } from "rxjs/operators";
import { Location } from '@angular/common';
import { LinkService } from "./services/link.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Deeplinks, CallNumber,ScreenOrientation]
})
export class AppComponent {

  isConnected: boolean = false;


  rootPage = "UsinghelpPage";
  hierarchy: string; // type of user (client or contractor)
  // client
  pagesOn: Array<IMenuItem>;
  lastBack;
  allowClose = false;
  pagesOff: Array<IMenuItem> = [];
  pagesOffPresta: Array<IMenuItem>;
  @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;

  elementSelected;
  // contractor
  pagesCoOn: Array<IMenuItem>;
  pagesCoOff: Array<IMenuItem>;
  pagesLogout: Array<IMenuItem>;
  protected app_version: string;

  customeService = { title: 'Service Soul & Beauty', image: 'assets/img/info.png' };
  customeServiceFaq = { title: 'Aide et Faq', image: 'assets/img/help.png' };
  result: any;
  picture_client: any = 'https://market.soulnbeauty.be/files/clients/16/16.png';
  bigImg = null;
  bigSize = '0';

  smallImg = null;
  smallSize = '0';
  static injector: Injector;
  allLinks;
  selectedElementPro;
  selectedElement

  constructor(private platform: Platform,
    public http: HttpClient,
    public modal: ModalController,
    public router: Router,
    public links:LinkService,
    private _location: Location,
    public alertController: AlertController,
    private screenOrientation: ScreenOrientation,
    private storage: StorageService,
    private zone: NgZone,
    private alertCtrl: AlertController,
    private callNumber: CallNumber,
    private events: Events,
    private iab: InAppBrowser,
    private authUserProvider: AuthUserProvider,
    private authCoProvider: AuthContractorProvider,

    private toastCtrl: ToastController,

  ) {

   
    this.listenToLoginEvents();
    this.initializeApp();
    this.setupListener();

    // For Client
    this.pagesOn = [
      { title: 'Accueil', uri: "homePage", component: 'homePage', image: 'assets/img/home.png', selected: true },
      { title: 'Mes réservations', uri: "booking", component: 'Orders', image: 'assets/img/calendar.png', selected: false },
      { title: 'Mon compte', component: 'Profile', uri: "profil", image: 'assets/img/user.png', 'selected': false },
      { title: 'Offres spéciales', component: 'offer', uri: "offer", image: 'assets/img/offer.png', 'selected': false },
      { title: 'Déconnexion', component: 'logout', image: 'assets/img/unlink.png' ,selected: false },
      { title: 'Aide et Faq', component: 'af', image: 'assets/img/help.png',selected: false },
      ,{ title: 'Code de bonne conduite', component: 'cb', image: 'assets/img/good.png',selected: false, link:"https://soulnbeauty.be/code?scroll=CLIENT"}
    ];

    this.pagesOn = this.pagesOn.filter(function (el) {
      return el != null;
    });

    // For Contractor
    this.pagesCoOn = [

      {title: 'Accueil', uri: "homeCoPage", component: 'homeCoPage', image: 'assets/img/home.png', selected: true },
      { title: 'Dashboard', component: 'DashboardPage', uri: "dash", image: 'assets/img/dashboard.png', selected: false },
      { title: 'Services', uri: "feedscoservice", component: 'FeedsServiceCoPage', image: 'assets/img/services.png', selected: false },
      { title: 'Établissements', uri: "barberCoPage", component: 'BarberShopCoPage', image: 'assets/img/Etablissement.png', selected: false },
      { title: 'Demandes', component: 'OrdersCoPage', uri: "bookingCo", image: 'assets/img/demandes.png', selected: false },
      { title: 'calendrier', component: 'ContractorsCalendarCustomerPage', uri: "calendar", image: 'assets/img/calendar.png', selected: false },

      { title: 'Scanner', component: 'ScannerQrCodeCoPage', uri: "scanner", image: 'assets/img/scan.png', selected: false },
      { title: 'Mon compte', component: 'ProfileCoPage', uri: "profilco", image: 'assets/img/user.png', selected: false },
      { title: 'Transactions', uri: "ribCoPage", component: 'RibCoPage', image: 'assets/img/ribcard.png', selected: false },
      { title: 'Déconnexion', component: 'logout', image: 'assets/img/unlink.png',selected: false  },
      { title: 'Aide et Faq', component: 'af', image: 'assets/img/help.png', selected: false }
      ,{ title: 'Code de bonne conduite', component: 'cb', image: 'assets/img/good.png',selected: false, link:"https://soulnbeauty.be/code?scroll=PRESTA"}
    ];
    this.pagesCoOn = this.pagesCoOn.filter(function (el) {
      return el != null;
    });

    // logOut pages
    this.pagesOff = [
      { title: 'Accueil', component: 'homePage', uri: "homePage", image: 'assets/img/home.png', selected: true },
      { title: 'Se connecter', component: 'WelcomePage', uri: "welcomePage", image: 'assets/img/user.png', selected: false },
      { title: 'Offres spéciales', component: 'offer', uri: "offer", image: 'assets/img/offer.png', 'selected': false },
      ,{ title: 'Aide et Faq', component: 'af', image: 'assets/img/help.png' }
      ,{ title: 'Code de bonne conduite', component: 'cb', image: 'assets/img/good.png',selected: false, link:"https://soulnbeauty.be/code?scroll=CLIENT"}
    ];

    this.pagesOff = this.pagesOff.filter(function (el) {
      return el != null;
    });

     // logOut pages
     this.pagesOffPresta = [
      { title: 'Accueil', component: 'homeCoPage', uri: "homeCoPage", image: 'assets/img/home.png', selected: true },
      { title: 'Se connecter', component: 'WelcomePage', uri: "welcomePage", image: 'assets/img/user.png', selected: false },
      ,{ title: 'Aide et Faq', component: 'af', image: 'assets/img/help.png', selected: false }
      ,{ title: 'Code de bonne conduite', component: 'cb', image: 'assets/img/good.png',selected: false, link:"https://soulnbeauty.be/code?scroll=PRESTA"}
    ];

    this.pagesOffPresta = this.pagesOffPresta.filter(function (el) {
      return el != null;
    });


  if (this.storage && this.storage.get("user")) {
      this.storage.get("user").then(x => {
        if (x == "cl") {
          this.getUserInfo();
        }
      });
    }
    this.links.getUrls().subscribe(x=>{
      this.allLinks = x.result;
     });
    this.updateMenu(false);
  }

  initMenu(pagesOff: Array<IMenuItem>){

    
    console.log(pagesOff)
   if(pagesOff && pagesOff.length>=1){
    for(var i=0; i<pagesOff.length; i++) {
      console.log( pagesOff[i])
      if(pagesOff[i]){
      pagesOff[i].selected = false;
      }
    }
    }
  }

  initializeApp() {

    this.platform.ready().then(() => {
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

      setTimeout(() => {
        SplashScreen.hide();
      }, 2000);
    });
 

const hideStatusBar = async () => {
  await StatusBar.hide();
};

   this.backButton();

  }


  backButton(){

    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.log('Back press handler!');
      if (this._location.isCurrentPathEqualTo('/homeCoPage') || this._location.isCurrentPathEqualTo('/homePage')) {
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        processNextHandler();
      } else {
        this._location.back();
      }

    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      console.log('Handler called to force close!');
      this.alertController.getTop().then(r => {
        if (r) {
          navigator['app'].exitApp();
        }
      }).catch(e => {
        console.log(e);
      })
    });
  }

  async showExitConfirm() {

    let confirm = this.alertCtrl.create({
      header: 'Soulnbeauty',
      message: "Voulez-vous sortir de l'application",
      backdropDismiss: false,
      buttons: [
        {
          text: 'Annuler',
          handler: () => {
            return true;
          }
        },
        {
          text: 'Ok',
          handler: () => {
            navigator['app'].exitApp();
          }
        }
      ]
    });
    (await confirm).present();
  }

  goToHomePage() {

     if (localStorage.getItem('hierarchy') == 'CO') {
      this.router.navigateByUrl('HomeCoPage');
    } else {
      this.router.navigateByUrl('HomePage');
    }

  }


  /**
   * 
   * @param number 
   */
  call() {
    this.callNumber.callNumber(this.allLinks.telephone.replace(")","").replace("(","").replace(/\s/g, '') , true)
      .then(res => console.log('Launched dialer!', res))
      .catch(err => console.log('Error launching dialer', err));

  }

  isSelected(elmt:any) {

    return elmt?elmt.selected:null;
    

  }

  /**
   * listen for the user login event
   */
  listenToLoginEvents() {
    this.events.subscribe('user:login', () => {
      this.getUserInfo();
    });


    this.events.subscribe('contractor:home', () => {
      this.hierarchy = 'CO';
      this.updateMenu(false);
    });

    this.events.subscribe('user:home', () => {
      this.hierarchy = 'CL';
      this.updateMenu(false);

    });


     this.events.subscribe('user:login', () => {
      this.getUserInfo();
    });
    this.events.subscribe('user:checkLocalStorage', () => {
      this.getUserInfoInLocalStorage(true);

    });

    this.events.subscribe('usercl:thumbnail', (data) => {
      if (data) {
        this.createThumbnail(data, false);
      }
    });

    this.events.subscribe('user:updateProfil', () => {
      this.getUserInfoInLocalStorage(true);
    });
    this.events.subscribe('user:updatePicture', (data) => {
      this.getUserInfoInLocalStorage(true, true);
    });

    this.events.subscribe('user:menu', (data) => {
      this.initMenu(this.pagesOn);
      if(this.pagesOn){
      this.pagesOn[0].selected = true;
      }

    });

    this.events.subscribe('contractor:menu', (data) => {
      
      this.initMenu(this.pagesCoOn);
      if(this.pagesCoOn){
      this.pagesCoOn[0].selected = true;
      }

    });

    this.events.publish('contractor:menu');

    this.listenToLoginCoEvents();
  }

  listenToLoginCoEvents() {
    this.events.subscribe('contractor:login', () => {
      this.getCoInfo();

    });


    this.events.subscribe('contractor:checkLocalStorage', () => {
      this.getCoInfoInLocalStorage();
      //this.receiveMessagePresta();

    });
    this.events.subscribe('contractor:updateProfil', () => {
      this.getCoInfoInLocalStorage(true);
    });
    this.events.subscribe('contractor:updateAccountBank', () => {
      this.getCoInfoInLocalStorage(true);
    });

    this.events.subscribe('contractor:updateAccountBank', () => {
      this.getCoInfoInLocalStorage(true);
    });

    this.events.subscribe('isConnectedButton', (x) => {
      if(x==0){
        this.hierarchy = 'CO';
      }
      else{
        this.hierarchy = 'CL';
      }
      this.isConnected = false;
      
    });
  }

  

  /**
   * Récupère les infos de l'utilisateur via la page de connexion (login)
   */
  getUserInfo() {

    //this.receiveMessageClient();
    this.isConnected = true;
    this.authUserProvider.getToken().subscribe((results) => {
      if (results.succes) {
        let imgDefault = false;
        this.isConnected = true;
        this.hierarchy = 'CL';


        this.result = results.result;
        this.authCoProvider.setInfoUser(this.result);
        console.log(this.authCoProvider.getInfoUser());
        // default value if picture profile not found
        if (results.result.PICTURE_URL_B64.length === 22) {
          this.bigImg = `assets/img/profile-sl.png`;
          imgDefault = true;
        }
        this.createThumbnail(results.result.PICTURE_URL_B64, imgDefault);
        this.toastNotification('Connexion réussie');
      } else {
        // failed to connect (invalid token)
        console.log('failed to connect (invalid token)');
        this.isConnected = false;
        this.authUserProvider.logout(); // clear local storage after failed to login
        this.toastNotification(results.message);
        this.router.navigate(['homePage'], { state: { isConnectedButton: false } });
      }
    });
  }

  /**
   * Récupère les infos du prestataire via la page de connexion (login)
   */
  getCoInfo() {

    this.isConnected = true;
    this.authCoProvider.getToken().subscribe((results) => {
      if (results.succes) {

        this.isConnected = true;
        this.hierarchy = 'CO';
        console.log(results.result);
        this.result = results.result;
        this.authCoProvider.setInfoUser(this.result);
        let imgDefault;
        if (results.result.PICTURE_URL_B64.length === 22) {
          this.bigImg = `assets/img/profile-sl.png`;
          imgDefault = true;
        }
        this.createThumbnail(results.result.PICTURE_URL_B64, imgDefault);
        this.toastNotification('Connexion réussie');
      } else {
        console.log('failed to connect (invalid token)');
        this.isConnected = false;
        this.authUserProvider.logout(); // clear local storage after failed to login
        this.toastNotification(results.message);
        this.router.navigate(['homePage'], { state: { isConnectedButton: false } });
      }
    });
  }

  /**
   * Récupère les infos dans le localStorage
   * @param {boolean} refreshCache
   * @param {boolean} goToProfilPage
   */
  getUserInfoInLocalStorage(refreshCache: boolean = true, goToProfilPage: boolean = false) {

    this.authUserProvider.checkTokenLocalStorage(refreshCache).subscribe((results) => {
      let imgDefault = false;
      this.isConnected = true;
      this.hierarchy = 'CL';

      if(results && results.succes){

      this.result = results.result;
      
      if (results.result.PICTURE_URL_B64.length === 22) {
        this.bigImg = `assets/profile-sl.png`;
        imgDefault = true;
      }
      this.events.publish('usercl:profile', this.result);
      this.createThumbnail(results.result.PICTURE_URL_B64, imgDefault);
      if (goToProfilPage) {
        localStorage.setItem("params", JSON.stringify(this.result));

        this.router.navigate(['profil'], { state: { params: this.result, from: "root" } });
      }
    }
    else{
      this.isConnected = false;
    }
    });
  }

  /**
   *
   * @param bigImg
   * @param imgDefault
   */
  createThumbnail(bigImg, imgDefault) {
    if (imgDefault) {
      this.smallImg = `assets/img/profile-sl.png`;
    } else {
      this.smallImg = bigImg;

    }
  }

  /**
   * Mise à jour de la photo de l'utilisateur
   */
  updateAvatar() {
    this.result['img'] = this.smallImg;
    localStorage.setItem("data", JSON.stringify({ data: this.result, profil: "cl", from: "root" }));
    this.router.navigate(['picture'], { state: { data: this.result, profil: "cl", from: "root" } });
  }

  /**
   * Mise à jour de la photo de l'utilisateur
   */
  updateAvatarCo() {

      this.result['img'] = this.smallImg;
      console.log(this.result)
      this.router.navigate(['picture'], { state: { data: this.result, profil: this.hierarchy.toLowerCase(), from: "root" } });
    
  }

  /**
   * Mise à jour de la photo de l'utilisateur
   */
  goToProfil() {

    localStorage.setItem("params", JSON.stringify(this.result));

    if (this.hierarchy === 'CL') {
      
      this.router.navigate(["profil"], { state: { params: this.result, from: "root" } });
    }
    if (this.hierarchy === 'CO') {
      this.router.navigate(['profilco'], { state: { params: this.result, from: "root" } });
    }
  }

   /**
   * Mise à jour de la photo de l'utilisateur
   */
    goToProfilCL() {
      localStorage.setItem("params", JSON.stringify(this.result));

        this.router.navigate(["profil"], { state: { params: this.result, from: "root" } });
    }

     /**
   * Mise à jour de la photo de l'utilisateur
   */
  goToProfilCO() {
      this.router.navigate(['profilco'], { state: { params: this.result, from: "root" } });
  }

  /**
   *
   * @param img
   * @param {number} MAX_WIDTH
   * @param {number} MAX_HEIGHT
   * @param {number} quality
   * @param callback
   */
  generateFromImage(img, MAX_WIDTH: number = 700, MAX_HEIGHT: number = 700, quality: number = 1, callback) {
    let canvas: any = document.createElement("canvas");
    let image = new Image();

    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = 200; // default value
      canvas.height = 200; // default value
      let ctx = canvas.getContext("2d");

      ctx.drawImage(image, 0, 0, width, height);

      let dataUrl = canvas.toDataURL('image/png', quality);

      callback(dataUrl)
    };
    image.src = img;
  }

  /**
   *
   * @param data_url
   * @returns {string}
   */
  getImageSize(data_url) {
    let head = 'data:image/png;base64,';
    return ((data_url.length - head.length) * 3 / 4 / (1024 * 1024)).toFixed(4);
  }

  /**
   * Retrieve contractor's picture
   * @param id
   * @returns {string}
   */
  getPictureCo(result) {
    this.smallImg = this.result.PICTURE_URL_B64;
  }

  /**
* Récupère les erreurs survenues lors de la connexion à l'API
* @param {Response | any} error
* @returns {Observable<any>}
*/
  protected handleError(error: Response | any): Observable<any> {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;

    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.log(errMsg);
    return Observable.throw(errMsg);
  }

  /**
   * Retrieve client's picture
   * @param id
   * @returns {string}
   */
  getPictureCl(id) {
    return (id) ? `https://market.soulnbeauty.be/files/clients/${id}/${id}.png` : `assets/img/profile-sl.png`;
  }

  /**
   * Récupère les infos dans le localStorage
   */
  getCoInfoInLocalStorage(refreshCache: boolean = false) {
    this.authCoProvider.checkTokenLocalStorage(refreshCache).subscribe((results) => {
      this.isConnected = true;
      this.hierarchy = 'CO';
      if(results){
      this.result = results.result;
      this.getPictureCo(this.result);
      this.events.publish('userco:profile', this.result);
      }
      else{
       
          this.isConnected = false;
        
      }
    });
  }

  /**
   * Open menu page
   * @param page
   */
  openPage(page, pageList) {
    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    console.log(this.elementSelected);

     
    this.initMenu(pageList);

    page.selected = true;
    if (page.component == "offer") {
      localStorage.setItem("params", JSON.stringify(this.result));
      this.router.navigate([page.uri], { state: { type: "promotion" } });
    }
    else if( page.component =="af")
    {
    
      this.openUrlFaq(this.allLinks.faq);
       
    }
    else if(page.component == "cb"){
      this.goToUrl(this.allLinks.codeboneconduite);
    }

    else if (page.component !== 'logout') {
      localStorage.setItem("params", JSON.stringify(this.result));
      this.router.navigate([page.uri], { state: { params: this.result } });
    }


    else {
      this.logout();
    }
    
  
  }

  goToUrl(url) {
    const browser = 
    this.iab.create(url,'_self',{location:'no'});
  
    browser.show();
  }

  openAgency() {
    this.goToUrl(this.allLinks.support);
  }


  private openUrlFaq(url) {
    this.goToUrl(url);
  }

  onLogin() { 
    this.router.navigateByUrl('welcomePage');
  }

  setupListener() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        console.log('App opened with URL:', JSON.stringify(event));
        
        const openUrl = event.url;
        const domain = 'soulnbeauty.com';
  
        const pathArray = openUrl.split(domain);
          // The pathArray is now like ['https://devdactic.com', '/details/42']
  
          // Get the last element with pop()
           const appPath = pathArray.pop(); 
           if(appPath && appPath.includes("/thank"))
           {
           
            const params = appPath.split("?");
            const finalParams= params[1].split("&");
            this.router.navigate(
             ['/thank'],
             { queryParams: { succes:finalParams[0].split("=")[1], amount:finalParams[1].split("=")[1] } }
           );
  
           }
           
          else if (this.hierarchy === 'CO') {
              this.router.navigateByUrl("homeCoPage");
            }
          
          else{
            this.router.navigateByUrl("homePage");
          }
      });
    });
  }

  // Déconnexion de l'utilisateur connecté
  logout() {  
    if(this.pagesOn){
      this.pagesOn[0].selected = true;

      }
    this.isConnected = false;
   
    this.storage.init();
    this.storage.get('token').then((token) => {
      console.log(token);
    });

    localStorage.setItem("isConnectedButton","0");
    this.authUserProvider.logout();
    this.updateMenu(true);
    
  }

  updateMenu(active){
    if(this.elementSelected){
      this.elementSelected.selected = false;
    }
    if (this.hierarchy === 'CO') {
      
      this.initMenu(this.pagesOffPresta);
      this.pagesOffPresta[0].selected = true;

      if(active){
      this.router.navigate(['homeCoPage']);
    }

    }
    else{
      
      this.initMenu(this.pagesOff);
      this.pagesOff[0].selected = true;
      if(active){
      this.events.publish('user:connexion');
      this.router.navigate(['homePage']);

      }
    }
  }

  /**
   * Notification
   * @param msg
   * @param {string} position
   */
  async toastNotification(msg: any, position: string = 'bottom') {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: "bottom",
      cssClass: 'dark-trans',
      buttons: [
        {
          side: 'end',
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ]
    });
    toast.present();
  }

}
