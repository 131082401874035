/**
 * Created by Bugatti on 06/10/2017.
 */
import {ErrorHandler, Injectable} from '@angular/core';
import {BaseService} from "../base.service";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import { Auth } from 'src/app/class/auth';
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn:'root'
})
export class ChangePasswordService extends BaseService {

  /**
   *
   * @param http
   */
  constructor(private http: HttpClient) {
    super();
  }

  /**
   *
   * @returns {string}
   */
  getEntityName() {
    return 'changePassword';
  }

  /**
   * Mise à jour du mot de passe d'un utilisateur via API
   * @param data
   * @param token
   * @returns {Observable<R|T>}
   */
  changePassword(data: Object, token): Observable<any> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(this.getEntityUrl(), this.serialize(data), {headers: headers})
     .pipe(
      map((res) => res),
      catchError(this.handleError)
      
     ) 

  }

  /**
   * Retourne l'url de l'api
   * @returns {string}
   */
  protected getEntityUrl() {
    return this.baseUrl + this.getEntityName();
  }

}