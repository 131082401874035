import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Storage} from "@ionic/storage";

import { ChangePasswordService } from 'src/app/services/client/change.password.service';
//import {Storage} from "@ionic/storage/ngx";

/*
  Generated class for the ChangePasswordUserProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class ChangePasswordUserProvider {

  /**
   *
   * @param storage
   * @param changePassword
   */
  constructor(private storage: Storage,
              private changePassword: ChangePasswordService) {
    console.log('Hello ChangePasswordProvider Provider');
  }

  /**
   * Mise à jour mot de passe d'un utilisateur connecté
   * @param password
   * @returns {any}
   */
  changeNewPassword(password): Observable<any> {
    return Observable.create((observer) => {
      this.storage.get('token').then((token) => {
        this.changePassword.changePassword(password, token).subscribe((result) => {
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error(err);
        });
      });
    });
  }
}
