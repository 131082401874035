import { Injectable } from "@angular/core";
import { CanLoad, Router, Route, UrlTree, CanActivate, UrlSegment } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Events } from "../common/events";
import { AuthContractorProvider } from "../providers/auth-contractor/auth-contractor";
import { AuthUserProvider } from "../providers/auth-user/auth-user";
import { StorageService } from "../services/storage.service";


@Injectable(
    {
        providedIn: 'root'
    }
)
export class GuardConnect implements CanLoad {

    constructor(public router: Router,
        private events: Events
    ) {

    }
    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        if (localStorage.getItem("guideline") != "1") {
            localStorage.setItem("guideline", "1");
            this.router.navigateByUrl('help');
            return false;
        }


        return this.checkAuthentification();

    }

    /**
  * Connexion automatique si l'utilisateur a un token valide dans le localStorage
  */
    private checkAuthentification() {
        if (localStorage.getItem('token')) {
            if (localStorage.getItem('hierarchy') == 'CO') {
                return this.goToCO();
            } else {
                return this.goToCL(true);
            }
        }
        localStorage.setItem("isConnectedButton", "0");

            return this.goToCL();  
    }

    /**
   * Go to CO hierarchy
   */
    private goToCO() {
        this.events.publish('contractor:checkLocalStorage')
        this.events.publish('homeCoPage:checkcPage');
        this.router.navigateByUrl('homeCoPage');
        localStorage.setItem("isConnectedButton", "1");
        return false;
        
    }

    /**
     * Go to CL hierarchy
     */
    private goToCL(connected?) {

        localStorage.setItem("accesHomePage", "1");
        this.events.publish('user:checkLocalStorage');
        if(connected){
        localStorage.setItem("isConnectedButton", "1");
        }
        return true;
    }
}
