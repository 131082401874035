import { Injectable } from '@angular/core';
import {Platform} from "@ionic/angular";
import { Network } from '@awesome-cordova-plugins/network/ngx';

/*
  Generated class for the ConnectivityProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class ConnectivityProvider {

  private _onDevice: boolean;

  /**
   *
   * @param {Platform} platform
   * @param {Network} network
   */
  constructor(public platform: Platform,
              public network: Network) {
    this._onDevice = this.platform.is('cordova');
  }

  /**
   * Si on est connecté à Internet
   * @returns {boolean}
   */
  isOnline(): boolean {
    if (this._onDevice && this.network.type) {
      return this.network.type != 'none';
    } else {
      return navigator.onLine;
    }
  }

  /**
   *  Si on est hors ligne (Pas accès à Internet)
   * @returns {boolean}
   */
  isOffline(): boolean {
    if (this._onDevice && this.network.type) {
      return this.network.type == 'none';
    } else {
      return !navigator.onLine;
    }
  }

  /**
   * Connexion
   * @returns {any}
   */
  watchOnline(): any {
    return this.network.onConnect();
  }

  /**
   *  Deconnexion
   * @returns {any}
   */
  watchOffline(): any {
    return this.network.onDisconnect();
  }
}
