import { Injectable } from '@angular/core';
import {Storage} from "@ionic/storage";
import { Observable } from 'rxjs';
import { Events } from 'src/app/common/events';
import { OrdersService } from 'src/app/services/client/orders.service';
import {ConnectivityProvider} from "../connectivity/connectivity";

/*
  Generated class for the PaidOrdersUserProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class PaidOrdersUserProvider {

  constructor(private storage: Storage,
              private connectivityService: ConnectivityProvider,
              private paidService: OrdersService,
              private events: Events) {
  }

  /**
   * Paid order
   * @param order
   * @returns {any}
   */
  paidOrder(order): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.paidService.paidOrder(order, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }


  /**
   * Cancel Paid order
   * @param order
   * @returns {any}
   */
  cancelPaidOrder(order): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.paidService.cancelPaidOrder(order, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }
}
