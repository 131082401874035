import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Storage} from "@ionic/storage";
import { ContractorChangePasswordService } from 'src/app/services/prestataire/contractor.change.password.service';

/*
  Generated class for the ChangePasswordContractorProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class ChangePasswordContractorProvider {

  constructor(private storage: Storage,
              private changePassword: ContractorChangePasswordService) {
    console.log('Hello ChangePasswordContractorProvider Provider');
  }

  /**
   * Mise à jour mot de passe d'un prestataire connecté
   * @param password
   * @returns {any}
   */
  changeNewPassword(password): Observable<any> {
    return Observable.create((observer) => {
      this.storage.get('token').then((token) => {
        this.changePassword.changePassword(password, token).subscribe((result) => {
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error(err);
        });
      });
    });
  }

}
