import { NgModule } from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {LoaderSpinnerComponent} from "./loader-spinner";

@NgModule({
  imports: [
    IonicModule
  ],
  declarations: [
    LoaderSpinnerComponent
  ],
  exports: [
    LoaderSpinnerComponent
  ]
})
export class LoaderSpinnerComponentModule {}
