/**
 * Created by Bugatti on 06/10/2017.
 */
import {Injectable} from '@angular/core';
import {BaseService} from "../base.service";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn:'root'
})
export class ForgetPasswordService extends BaseService {

  /**
   *
   * @param http
   */
  constructor(private http: HttpClient) {
    super();
  }

  /**
   *
   * @returns {string}
   */
  getEntityName() {
    return 'requestResetPassword';
  }

  /**
   * Réinitialisation du mot de passe oublié via API à partir de l'email
   * @param email
   * @returns {Observable<R|T>}
   */
  postInitPassword(email): Observable<any> {
    return this.http.post(this.getEntityUrl(), this.serialize(email), {headers: this.headers})
    .pipe(
      map((res) => res),
      catchError(this.handleError)
      
     ) 
  }

    /**
   * Réinitialisation du mot de passe oublié via API à partir de l'email
   * @param email
   * @returns {Observable<R|T>}
   */
  resetConfirmMail(user): Observable<any> {
    return this.http.post(this.baseUrl +"resendValidateMail", this.serialize(user), {headers: this.headers})
    .pipe(
      map((res) => res),
      catchError(error=>{return this.handleError(error);})
      
     ) 
  }

  

  /**
   * Retourne l'url de l'api
   * @returns {string}
   */
  protected getEntityUrl() {
    return this.baseUrl + this.getEntityName();
  }

}