/**
 * Created by Bugatti on 06/10/2017.
 */
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {BaseService} from "../base.service";
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { Auth } from 'src/app/class/auth';


@Injectable({
  providedIn:'root'
})
export class ContractorForgetPasswordService extends BaseService {

  /**
   *
   * @param http
   */
  constructor(private http: HttpClient) {
    super();
  }

  /**
   *
   * @returns {string}
   */
  getEntityName() {
    return 'requestResetPasswordPrestation';
  }

  /**
   * Réinitialisation du mot de passe oublié via API à partir de l'email
   * @param email
   * @returns {Observable<R|T>}
   */
  postInitPassword(email): Observable<Auth> {
    return this.http.post(this.getEntityUrl(), this.serialize(email), {headers: this.headers})
    .pipe(map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
  )
  }

   /**
   * Réinitialisation du mot de passe oublié via API à partir de l'email
   * @param user
   * @returns {Observable<R|T>}
   */
  resetConfirmMail(user): Observable<any> {
    return this.http.post(this.baseUrl +"resendValidateMail", this.serialize(user), {headers: this.headers})
    .pipe(map(res => res),
    catchError(this.handleError),
    publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
    refCount() // used to keep the observable alive for as long as there are subscribers.
  )
  }

  

  /**
   * Retourne l'url de l'api
   * @returns {string}
   */
  protected getEntityUrl() {
    return this.baseUrl + this.getEntityName();
  }

}
