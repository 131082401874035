import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {SearchLocationPage} from './search-location';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

@NgModule({
  declarations: [
    SearchLocationPage
  ],
  imports: [
    CommonModule,
    IonicModule,
    GooglePlaceModule,
    FormsModule,
    CommonModule,

  ]
})
export class SearchLocationPageModule {
}
