import { Component, ViewChild, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavController, NavParams, ToastController, IonContent } from '@ionic/angular';
import { PaidOrdersUserProvider } from "../../providers/paid-orders-user/paid-orders-user";
import { FormBuilder, Validators } from "@angular/forms";
import { OrderUserProvider } from "../../providers/order-user/order-user";
import { InAppBrowser, InAppBrowserEvent } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Events } from 'src/app/common/events';
import { ActivatedRoute, Router } from '@angular/router';
import { EvaluationComponent } from '../evaluation/evaluation';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
/**
 * 
 * Generated class for the FeedsReservationPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-feeds-reservation',
  templateUrl: 'feeds-reservation.html',
  styleUrls: ['./feeds-reservation.scss'],
  providers: [FormBuilder,CallNumber]
})
export class FeedsReservationPage {

  @Input() order: any;
  @Input() historic: any;
  requestAccepted = 'Demande de réservation acceptée';
  picture: any = 'https://market.soulnbeauty.be/files/clients/16/16.png';
  isQrCode: boolean = false;
  @ViewChild(IonContent) content: IonContent
  noteForm;
  _loading;
  intervalPool;
  time: number = 0;
  disableBtn;
  totalPrice;
  rating: number = 0;
  isNote = false;
  info = false;
  @Input() modal;
  @Input() from: boolean;
  imageUrl = 'assets/img/logo.png';
  param: any;
  style = {};
  constructor(public navCtrl: NavController,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private callNumber: CallNumber,
    public loadingCtrl: LoadingController,
    public viewCtrl: ModalController,
    public router: Router,
    private route: ActivatedRoute,
    private alertCtrl: AlertController,
    private ref: ChangeDetectorRef,
    private noteProvider: OrderUserProvider,
    private fb: FormBuilder,
    private events: Events,
    private iab: InAppBrowser,
    private paidProvider: PaidOrdersUserProvider) {


  }


  ngOnInit() {

    if (this.modal) {

      if (this.from) {

        this.toastNotification(`Votre avis est important! Chaque évaluation aide les autres clients à effectuer une réservation en toute connaissance de cause. Merci de prendre une minute pour évaluer le service effectué`);

      }
    }
    else {
      const data: any = JSON.parse(localStorage.getItem("data"));
      if (this.router.getCurrentNavigation()
        && this.router.getCurrentNavigation().extras &&
        this.router.getCurrentNavigation().extras.state) {
        const state = this.router.getCurrentNavigation().extras.state;
        this.order = state.order;
        this.historic = state.historic;
        this.from = state.from;
       

      }
      else {
        this.order = data.order;
        this.historic = data.historic;
        this.from = data.from;
      }
    }


    if (this.order) {
      this.order.ID_RESERVATION_TEXT = this.createIdService(this.order.ID_CLIENT, this.order.ID_RESERVATION);
    }

    this.updateStylePromotion(this.order);
    this.historic = !!this.historic;
    this.param = this.route.snapshot.paramMap.get('id');

    this.noteForm = this.fb.group({
      comment: ['', {
        validators: [Validators.required]
      }],
      rating: ['', {
        validators: [Validators.required]
      }]
    });

    this.noteForm.valueChanges
      .subscribe((changedObj: any) => {
        this.disableBtn = this.noteForm.valid;
      });

    console.log("-----START POOLING--------")
    if (!this.historic && this.order) {
      this.startTimerPool(this.order.ID_RESERVATION);
    }
  }


  async goToAboutAlbumContractor(id_presta) {
    localStorage.setItem("data", JSON.stringify({ contractorId: id_presta }));
    this.router.navigate(['feedsAlbumByContractorPage'], { queryParams: { contractorId: id_presta } });
  }

  async toastNotification(msg: any, position: string = 'bottom') {
    let toast = await this.toastCtrl.create({
      message: msg,
      position: "bottom",
      cssClass: 'dark-trans',
      buttons: [
        {
          side: 'end',
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ]
    });
    toast.present()
  }

  /**
  * 
  * @param number phone number  
  */
  public call(number) {
    this.callNumber.callNumber(number, true)
      .then(res => console.log('Launched dialer!', res))
      .catch(err => console.log('Error launching dialer', err));
  }


  private updateStylePromotion(detailReservation: any) {
    if (detailReservation && detailReservation.PSNBPROMOTION == '1') {
      this.style = {
        'text-decoration': 'line-through', 'color': 'black',
        'font-weight': 'bold',
        'margin-right': '15px'
      };
    }
  }

  ngOnDestroy() {
    this.pauseTimerPool();
  }



  setMyStyles() {
    return {
      'background': 'url(assets/img/nscreen/thankblur.png)',
      'background-position': 'center',
      'background-size': 'cover'
    };
  }


  private createIdService(clientId, reservationId) {
    return "S-" + clientId + "-" + reservationId;
  }


  updateBookingEvent() {
    this.events.publish('user:ratingContractor');
  }

  async evaluation() {

    const profileModal = await this.modalCtrl.create({
      component: EvaluationComponent,
      cssClass: 'transparent-modal',
      componentProps: {
        idReservation: this.order.ID_RESERVATION
      }
    });
    profileModal.present();
    profileModal.onWillDismiss().then((result: any) => {
      let data = result.data;
      console.log(data);
      if (data) {
        if (data.succes) {
          this.order.NOTE_COMMANDE = 1;
          this.updateBookingEvent(); // update event
          this.toastNotification(data.message);
          if (this.from) {
            this.dismiss();
          }
          return;
        }
        this.toastNotification(data.message);
      }
    })
  }

  pooling(idReservation) {

    const field = `?id_reservation=${idReservation}`;
    this.noteProvider.getMyOrdersById(field).subscribe(x => {

      if (x.result) {
        if (x.result[0].ETAT_COMMANDE == '3') {
          this.order.ETAT_COMMANDE_VALUE = 3;
          this.order.CODE_COMMANDE = null;
          this.evaluation();
          this.ref.detectChanges();
          this.pauseTimerPool();
        }
      }
    });
  }

  startTimerPool(idReservation) {
    this.intervalPool = setInterval(() => {
      this.time++;
      // console.log("TENTION NUMERO:" + this.time);
      this.pooling(idReservation);
    }, 2000)
  }

  pauseTimerPool() {
    clearInterval(this.intervalPool);
  }


  display() {
    this.info = !this.info;
    this.ref.detectChanges();

  }


  /**
   * Show address
   * @param loc
   * @returns {any}
   */
  getLocalisation(loc) {
    if (loc == 1) {
      return 'Etablissement';
    }
    return 'Domicile';
  }

  /**
   * Retrieve contractor's picture
   * @param id
   * @returns {string}
   */
  getPictureCo(id) {
    return (id) ? `https://market.soulnbeauty.be/files/prestataires/${id}/${id}.png` : `assets/img/profile-sl.png`;
  }



  /**
   * Payment
   */
  onPaid() {
    let transaction = {
      id_reservation: this.order.ID_RESERVATION
    };
    this.paidProvider.paidOrder(transaction).subscribe((result) => {
      if (result.succes) {

        const browser =  this.iab.create( result.message.getCheckoutUrl,'_self',{location:'no'});
        browser.show();

        browser.on('loadstart').subscribe((event: InAppBrowserEvent) => {
          if (event.url.includes('success=1')) {
            browser.close(); // This will close InAppBrowser Automatically when closeUrl Started
            this.router.navigate(['thank'], { state: { order: this.order } });
          } else if (event.url.includes('success=0')) {
            browser.close(); // This will close InAppBrowser Automatically when closeUrl Started
          }
        });
      } else {
        this.toastNotification(result.message)
      }
    }, (error) => {
      this.toastNotification(error);
    });
  }


  /**
  * Confirm cancel order
  */
  async showConfirmCancel() {
    let showDialog = await this.alertCtrl.create({
      header: 'Voulez-vous annuler cette réservation ?',
      buttons: [
        {
          text: 'Oui',
          role: 'destructive',
          handler: () => {
            this.cancelOrder()
          }
        },
        {
          text: 'Non',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    await showDialog.present();
  }


  /**
 * Cancel order
 */
  async cancelOrder() {

    let order: Object = {
      id_reservation: this.order.ID_RESERVATION
    };


    this._loading = await this.loadingCtrl.create({
      message: "Annulation en cours..."
    })

    this._loading.present().then((x) => {
      this.paidProvider.cancelPaidOrder(order).subscribe((result) => {
        this._loading.dismiss();
        this.navCtrl.pop(); // Back navigation
        this.toastNotification(result.message, 'bottom');
      }, (error) => {
        this._loading.dismiss();
        this.toastNotification(error);
      });
    });

  }


  toggleCategory() {
    this.isQrCode = !this.isQrCode;
  }


  /**
 * Close
 */
  dismiss() {
    this.viewCtrl.dismiss();
  }


}
