import {Injectable} from '@angular/core';
import {Storage} from "@ionic/storage";
import { Observable } from 'rxjs';
import { Events } from 'src/app/common/events';
import { OrdersService } from 'src/app/services/client/orders.service';
import {ConnectivityProvider} from "../connectivity/connectivity";

/*
  Generated class for the OrderUserProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class OrderUserProvider {
  public notificationIsWaiting = false;
  public notificationData;
  constructor(private storage: Storage,
              private connectivityService: ConnectivityProvider,
              private ordersService: OrdersService,
              private events: Events) {
  }
 

  /**
   * Provider : Retrieve list of user orders services SoulnBeauty
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getUserOrdersServices(refreshCache: boolean = false,action,limite): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        // Load token if exists
        this.storage.get('token').then((token) => {
          // if token exists
          if (token) {
            // Reload data even if it is cached
            // if (refreshCache) {
            //   console.log('refresh...')
            //   this.ordersService.refreshCache(); // reinitialize the observable
            // }
            this.ordersService.getMyOrders(token,action,limite).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }


    /**
   * 
   * @param {string} id
   * @returns {Observable<any>}
   */
  getMyOrdersById(id:string): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        // Load token if exists
        this.storage.get('token').then((token) => {
          // if token exists
          if (token) {
            // Reload data even if it is cached
            this.notificationIsWaiting = false;
            this.ordersService.getMyOrdersById(token,id).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            this.notificationIsWaiting = true;

            console.log('no token')
          }
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Provider : Retrieve list of user orders services SoulnBeauty
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getMyServiceById(id): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        // Load token if exists
        this.storage.get('token').then((token) => {
          // if token exists
          if (token) {
           
            this.ordersService.getMyServiceById(token,id).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }



  /**
   * Provider : Retrieve list of user prestation services SoulnBeauty (Public method without token for call that)
   * @param refreshCache
   * @returns {Observable<any>}
   */
  getUserPrestationServices(refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        // Reload data even if it is cached
        if (refreshCache) {
          this.ordersService.clearPrestationServicesCache(); // reinitialize the observable
        }
        this.ordersService.getPrestationServices().subscribe((result) => {
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error('Une erreur est survenue lors du chargement des données' || err);
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }


  


    /**
   * Provider : Retrieve list of user prestation services SoulnBeauty (Public method without token for call that)
   * @param refreshCache
   * @returns {Observable<any>}
   */
  getServices(stringName): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        // Reload data even if it is cached
        console.log("helllllllo");
        this.ordersService.getServices(stringName).subscribe((result) => {
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error('Une erreur est survenue lors du chargement des données' || err);
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Provider : Retrieve list of contractor prestation by id (Public method without token for call that)
   * @param contractorId
   * @returns {Observable<any>}
   */
  getPrestationServicesById(contractorId): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.ordersService.getPrestationServicesById(contractorId).subscribe((result) => {
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error('Une erreur est survenue lors du chargement des données' || err);
        });
      });

    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }


    /**
   * Provider : Retrieve list of contractor prestation by id (Public method without token for call that)
   * @param contractorId
   * @returns {Observable<any>}
   */
     getPrestationServicesByIdPagination(contractorId,limit): Observable<any> {
      // If you are connected to Internet
      if (this.connectivityService.isOnline()) {
        return Observable.create((observer) => {
          this.storage.get('token').then((token) => {
          this.ordersService.getPrestationServicesByIdPagniation(contractorId,limit,token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          })});
        });
  
      } else {
        return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
      }
    }

  /**
   * Provider : Filter prestation services SoulnBeauty
   * @param filter
   * @returns {Observable<any>}
   */
  filterPrestationServices(filter: any): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          // if token exists
        
           
        this.ordersService.filterPrestationServices(filter,token).subscribe((result) => {
          // call complete if you want to close this stream (like a promise)
          // push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error('Une erreur est survenue lors du chargement des données' || err);
        });
      })
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }


    /**
   * Provider : Filter prestation services SoulnBeauty
   * @param filter
   * @returns {Observable<any>}
   */
  filterPrestationServicesLimite(filter: any,limite): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
        this.ordersService.filterPrestationServices(filter,token).subscribe((result) => {
          // call complete if you want to close this stream (like a promise)
          // push into subject
          observer.next(result);
          observer.complete();
        }, (err) => {
          observer.error('Une erreur est survenue lors du chargement des données' || err);
        })})
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Client : Note contractor
   * @param rating
   * @returns {Observable<any>}
   */
  noteContractor(rating: any): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.ordersService.noteContractor(rating, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Reserve service by client
   * @param service
   * @returns {Observable<any>}
   */
  reserveService(service): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.ordersService.reserveService(service, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Calendar Provider : Retrieve list of calendar services SoulnBeauty
   * @param service
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  getUserCalendarServices(service: any, refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          if (token) {
            // Reload data even if it is cached
            if (refreshCache) {
              this.ordersService.clearCalendarCache(); // reinitialize the observable
            }
            this.ordersService.getClientCalendarService(service, token).subscribe((result) => {
              //call complete if you want to close this stream (like a promise)
              //push into subject
              observer.next(result);
              observer.complete();
            }, (err) => {
              observer.error('Une erreur est survenue lors du chargement des données' || err);
            });
          } else {
            console.log('no token')
          }
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Book Benefits
   * @param order
   * @returns {any}
   */
  bookBenefits(order): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.ordersService.noteContractor(order, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  /**
   * Récupère les avis d'un prestataire
   * @param notice
   * @returns {Observable<any>}
   */
  getNotice(notice): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.ordersService.getNoticeService(notice, token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Une erreur est survenue lors du chargement des données' || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

}
