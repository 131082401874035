import { Injectable } from '@angular/core';
import {Storage} from "@ionic/storage-angular";

import {ConnectivityProvider} from "../connectivity/connectivity";
import {IUser} from "../../interfaces/IUser";
import { ContractorService } from 'src/app/services/prestataire/contractor.service';
import { UserService } from 'src/app/services/client/user.service';
import { OrdersService } from 'src/app/services/client/orders.service';
import { RegisterService } from 'src/app/services/client/register.service';
import { ForgetPasswordService } from 'src/app/services/client/forget.password.service';
import { ContractorShopService } from 'src/app/services/prestataire/contractor.shop.service';
import { Events } from 'src/app/common/events';
import { Observable } from 'rxjs';


/*
  Generated class for the AuthUserProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class AuthUserProvider {
 

  private _token;
  private _infoUser;

  constructor(private userService: UserService,
              private coService: ContractorService,
              private coOrdersService: OrdersService,
              private registerService: RegisterService,
              private forgetPassword: ForgetPasswordService,
              private coContractorShopService: ContractorShopService,
              private connectivityService: ConnectivityProvider,
              private storage: Storage,
              private events: Events) {
  }


  set infoUser(userInfo){

    this._infoUser =  userInfo;
  }

  get infoUser(){

    return this._infoUser;
  }

  /**
   * Connexion Provider : Permets d'authentifier un utilisateur
   * @param user
   * @returns {any}
   */
  signIn(user: Object): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.userService.postSignIn(user).subscribe((user) => {
          // set token if user found in DB
          if (user.succes) {
            this._token = user.result;
            this.storage.set('token', user.result);
            localStorage.setItem('token', user.result);

            // publish an event when a user is logging into app
            this.events.publish('user:login');
          }
          //call complete if you want to close this stream (like a promise)
          //push into subject
          observer.next(user);
          observer.complete();
        }, (err) => {
          observer.error('Impossible de se connecter au serveur' || err);
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }



    /**
   * On va renvoyer un lien de confirmation à l'utilisateur
   * @param user
   * @returns {any}
   */
     public deleteAccountuser(){
      // If you are connected to Internet
      if (this.connectivityService.isOnline()) {

           // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.storage.get('token').then((token) => {
          this.userService.deleteAccountuser(token).subscribe((result) => {
            //call complete if you want to close this stream (like a promise)
            //push into subject
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error(`Une erreur est survenue lors de l'enregistrement` || err);
          });
        });
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
    }
  }

  /**
   * Inscription Provider: Permets de créer un compte utilisateur
   * @param user
   * @returns {any}
   */
  signUp(user: IUser): Observable<any> {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.registerService.postSignUp(user).subscribe((user) => {
          // Création du compte utilisateur
          // push into subject and call complete if you want to close this stream (like a promise)
          observer.next(user);
          observer.complete()
        }, (err) => {
          observer.error('Impossible de se connecter au serveur');
        })
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

  cancelRemoveAccount(deletetoken,type) {
           // If you are connected to Internet
           if (this.connectivityService.isOnline()) {
            return Observable.create((observer) => {
              this.storage.get('token').then((token) => {
                this.userService.cancelRemoveAccount(token,deletetoken,type).subscribe((user) => {
                  // Création du compte utilisateur
                  // push into subject and call complete if you want to close this stream (like a promise)
                  observer.next(user);
                  observer.complete()
                }, (err) => {
                  observer.error('Impossible de se connecter au serveur');
                })
              });
            });
          } else {
            return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
          }
  }

  /**
   * On va initialiser le mot de passe de l'utilisateur à partir de son mail
   * @param email
   * @returns {any}
   */
  resetPassword(email) {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.forgetPassword.postInitPassword(email).subscribe((result) => {
          // push into subject and call complete if you want to close this stream (like a promise)
          observer.next(result);
          observer.complete()
        }, (err) => {
          let error = 'Une erreur est survenue lors de la connexion au serveur';
          observer.error('Connexion au serveur impossible' || error);
        })
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }

    /**
   * On va renvoyer un lien de confirmation à l'utilisateur
   * @param user
   * @returns {any}
   */
  resetConfirmMail(user) {
    // If you are connected to Internet
    if (this.connectivityService.isOnline()) {
      return Observable.create((observer) => {
        this.forgetPassword.resetConfirmMail(user).subscribe((result) => {
          // push into subject and call complete if you want to close this stream (like a promise)
          observer.next(result);
          observer.complete()
        }, (err) => {
          let error = 'Une erreur est survenue lors de la connexion au serveur';
          observer.error('Connexion au serveur impossible' || error);
        })
      });
    } else {
      return Observable.throw('Aucune connexion Internet' || 'This network has no Internet access');
    }
  }


  /**
   * On récupère le token sauvegardé dans le localStorage
   * dont on se sert pour récupérer les informations personnelles d'un user
   * @returns {any}
   * @param {boolean} refreshCache
   * @returns {Observable<any>}
   */
  checkTokenLocalStorage(refreshCache: boolean = false): Observable<any> {
    // If you are connected to Internet
    return Observable.create((observer) => {
      //Load token if exists
      this.storage.get('token').then((token) => {
        // if token exists
        
        if (token) {
          // Reload data even if it is cached
          // if(refreshCache) {
          //   this.userService.refreshCache();
          // }
          // this.userService.clearUserDetailsCache(); // reinitialize the observable TODO
          this.userService.getUserInfos(token).subscribe((result) => {
            this.storage.set('user_id', result.result.ID_CLIENT);
             result['token']= token;
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Impossible de se connecter au serveur' || err);
          })
        } else {
          observer.next(null);
          observer.complete()
        }
      });
    });
  }

  getAvis(field): Observable<any> {
    // If you are connected to Internet
    return Observable.create((observer) => {
      //Load token if exists
      this.storage.get('token').then((token) => {
        // if token exists
        if (token) {
          // Reload data even if it is cached
         
          this.userService.getAvis(token,field).subscribe((result) => {
            observer.next(result);
            observer.complete();
          }, (err) => {
            observer.error('Impossible de se connecter au serveur' || err);
          })
        } else {
          console.log('no token')
        }
      });
    });
  }

 

  /**
   * @description Retrieve user id
   * @returns {Observable<any>}
   */
  getUserId(): Observable<any> {
    return Observable.create((observer) => {
      this.storage.get('user_id').then((userId) => {
        observer.next(userId);
        observer.complete();
      }, (err) => {
        observer.error('Impossible de récupérer le userId' || err);
      })
    });
  }


  /**
   * On récupère le token de l'utilisateur qui vient de se connecter à l'app
   * dont on se sert pour récupérer les informations personnelles d'un user
   * @returns {any}
   */
  getToken(): Observable<any> {
    return Observable.create((observer) => {
      this.userService.getUserInfos(this._token).subscribe((result) => {
        this.storage.set('user_id', result.result.ID_CLIENT);
        this.storage.set('hierarchy', 'CL');
        localStorage.setItem('hierarchy', 'CL');
        observer.next(result);
        observer.complete();
      }, (err) => {
        observer.error('Impossible de se connecter au serveur' || err);
      })
    });
  }

  /**
   *
   * @returns {Promise<any>}
   */
  getHierarchy() {
    return localStorage.get('hierarchy');
    
  }

  /**
   * On supprime le token de l'utilisateur après sa déconnexion
   */
  logout() {
    this.clearAllCache();
    this.storage.remove('token').then(() => {
      console.log("remove token")
    });

    localStorage.clear();

    this.storage.remove('user_id').then(() => {
      console.log("remove user_id")
    });
    

    this.storage.remove('user').then(() => {
      console.log("remove user")
    });
    this.storage.remove('lang').then(() => {
      console.log("remove lang")
    });
    localStorage.setItem("guideline","1");
    

  }

  /**
   * @description Clean all cache after logout
   */
  clearAllCache() {
    this.userService.invalidateCache(); // clear cache (CL)
    this.coService.invalidateCache(); // clear cache (CO)

    this.coOrdersService.clearMyOrdersCache();
    this.coOrdersService.clearCalendarCache();
    this.coOrdersService.clearPrestationServicesCache();

    this.coContractorShopService.clearMyServicesCache();
    this.coContractorShopService.clearServicesCache();
    this.coContractorShopService.clearReservationCache();
    this.coContractorShopService.clearCalendarCache();
    this.coContractorShopService.clearSpecificCache();
    this.coContractorShopService.clearBarberShopServicesCache();
  }

}
