import { Component, ElementRef, Input, Renderer2, ViewChild } from "@angular/core";

import { Storage } from '@ionic/storage';

import { NavController, ModalController } from "@ionic/angular";
import { Events } from "src/app/common/events";
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Router } from "@angular/router";

declare var require: any;

var places = require('places.js');
var algoliasearch = require('algoliasearch');

//const client = algoliasearch('250ZI4PJS9', '7ed9dd7ab7374444293eaed1ffe1f633');
@Component({
  selector: 'page-search-location',
  templateUrl: 'search-location.html',
  styleUrls: ['./search-location.scss'],
  providers: [Geolocation]

})
export class SearchLocationPage {

  public  items = [];
  @ViewChild('adress') adress: ElementRef;
  @ViewChild('pop') pop;
  public _queryadress;

  localisation;
  callBackFilter;
  @Input() componentName;
  @Input() form;


  constructor(public nav: NavController,
    private geolocation: Geolocation,
    public modal: ModalController,
    public router: Router,
    private event: Events,
    private storage: Storage,
  ) {
    this.readHistoryStorage();


    if (this.callBackFilter) {
      this.localiseMe();
      this._queryadress = "Ma position";
    }

  }


  localiseMe() {

    this.geolocation.getCurrentPosition().then((resp) => {
      if (resp && resp.coords) {
        this.localisation = { lat: resp.coords.latitude, lng: resp.coords.longitude };
      }
    }).catch((error) => {
      console.log('Error getting location', error);
    });
  }
  

  // ngAfterViewInit() {

  //   let departs = places({
  //     appId: "250ZI4PJS9",
  //     apiKey: "381e88747139efd9db3920350967ba41",
  //     container: this.adress.nativeElement
  //   });


    
  //   departs.on('change', (e) => {
  //     this.localisation = e.suggestion.latlng;
  //     this._queryadress = e.suggestion.value;
  //   });

  //   departs.on('clear', (e) => {
  //     this._queryadress = '';
  //   });
  // }

  handleAddressChange(address: any) {
    this.localisation = {lng:address.geometry.location.lng(),lat:address.geometry.location.lat()};
      console.log(address.geometry.location);
    this._queryadress = address.formatted_address;
  }

  deleteHistory() {

    this.storage.set('locationAddress', []);
    this.items = []
  }

  public sendEnteredAdress() {
   
    this.event.publish(this.componentName, this._queryadress);
    this.event.publish("localisation", this.localisation);

    
    if (this._queryadress && this.form) {
      this.form.controls.address.setValue(this._queryadress);
    }
    this.saveAddressInStorage();
    this.modal.dismiss();
  }

  /**
  * read all addresses from the storage history
  * @param isDelete
  */
  private readHistoryStorage(isDelete?) {
    this.storage.get('locationAddress').then((data) => {
      if (data == null) {
        data = [];
      }
      this.items = data;
      this.saveAddressInStorage(isDelete);
    });
  }

  private contains(array, value) {

    return array.find(element => element.address == value);
  }

  /**
  * Save the entered address from the input into the storage
  * @param isDelete
  */
  private saveAddressInStorage(isDelete?) {
    if (this._queryadress && !this.contains(this.items, this._queryadress)) {
      this.items.push({ address: this._queryadress, localisation: this.localisation });
      this.checkListStorageAndSave();
    }
  }

  /**
   *  Update storage Adress
   */
  checkListStorageAndSave() {
    this.storage.get('locationAddress').then((data) => {
      if (data == null) {
        this.storage.set('locationAddress', this.items);
      }
      else {
        if (this._queryadress && !this.contains(data, this._queryadress)) {
          this.storage.set('locationAddress', this.items);

        }
      }
    });
  }



  /**
 * Set the value in the input tag
 * In term of the focus boolean
 * @param value, value to set
 */
  public initInputValue(value) {
    console.log(value);
    this._queryadress = value.address;
    this.localisation = value.localisation;
  }

}

