/**
 * Created by Bugatti on 06/10/2017.
 */
import {Injectable} from '@angular/core';
import {BaseService} from "../base.service";
import {Observable} from "rxjs";
import {HttpClient,HttpHeaders} from "@angular/common/http";
import {catchError, map, publishReplay, refCount} from "rxjs/operators";
import {CacheService} from "ionic-cache";
import { AuthContractorProvider } from 'src/app/providers/auth-contractor/auth-contractor';


@Injectable({
  providedIn:'root'
})
export class OrdersService extends BaseService {

  private _userOrders: Observable<any> = null;
  private _userP: Observable<any> = null;
  private _sP: Observable<any> = null;
  private _userId: Observable<any> = null;
  private _userCalendar: Observable<any> = null;
  private anyKey = 'any-offline';



  /**
   *
   * @param {HttpClient} http
   * @param {CacheService} cache
   */
  constructor(private http: HttpClient,
              private cache: CacheService,
              private anyContractorProvider:AuthContractorProvider) {
    super();
  }

  /**
   *
   * @returns {string}
   */
  getEntityName() {
    return 'getMyReservation';
  }

  /**
   * Return user orders via le token
   * @param token
   * @returns {Observable<any>}
   */
  getMyOrders(token,action,limite): Observable<any> {
    let headers = {'Jwt': token};
    let param ="?limit="+limite+"&&history="+action;

      this._userOrders = this.http.get(`${this.getEntityUrl(param)}`, {headers: headers}).pipe(
        map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount() // used to keep the observable alive for as long as there are subscribers.
    
      )
    // (@returns Observable)
    return this._userOrders;
  }

   /**
   * Return user orders via le token
   * @param token
   * @returns {Observable<any>}
   */
  getMyOrdersById(token,id): Observable<any> {
     let headers = {'Jwt': token};

   
      this._userOrders = this.http.get(`${this.getEntityUrl(id)}`, {headers: headers}).pipe(
        map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount(), // used to keep the observable alive for as long as there are subscribers.
      )
    // (@returns Observable)
    return this._userOrders;
  }

  /**
   * Reload data even if it is cached
   * Load item from cache if it's in cache or load from origin observable
   */
  clearMyOrdersCache() {
    this._userOrders = null;
  }

  clearCalendarCache() {
    this._userCalendar = null;
  }

  clearPrestationServicesCache() {
    this._userP = null;
  }

  clearServicesCache() {
    this._sP = null;
  }

  clearPrestationByIdServicesCache() {
    this._userId = null;
  }

  clearOrdersCache() {
    this._userOrders = null;
  }

  /**
   * Return prestation services (You can call this method whitout token)
   * @returns {Observable<R|T>}
   */
   getPrestationServices(): Observable<any> {
     if (!this._userP) {
         this._userP = this.http.get(`${this.baseUrl}getPrestation`).pipe(
          map(res => res),
          catchError(this.handleError),
          publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
          refCount(), // used to keep the observable alive for as long as there are subscribers.
        )
     }

     return this._userP;
     // (@returns Observable)

   }

    /**
   * Retourne la liste des services d'un prestataire connecté via le token
   * @returns {Observable<R|T>}
   */
  getMyServiceById(token, id): Observable<any> {
    let headers = {'Jwt': token};
    return this.http.get(`${this.baseUrl}getMyServices?id_prestation=${id}`, {headers: headers}).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount(), // used to keep the observable alive for as long as there are subscribers.
    )
  }

    /**
   * Return services (You can call this method whitout token)
   * @returns {Observable<R|T>}
   */
   getServices(filter): Observable<any> {

 
       this._sP = this.http.get(`${this.baseUrl}getServices${filter}`).pipe(
        map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount(), // used to keep the observable alive for as long as there are subscribers.
      )

     return this._sP;
     // (@returns Observable)

   }

  /**
   * Return all prestations for a contractor by id services (You can call this method whitout token)
   * @param contractor
   * @returns {Observable<any>}
   */
  getPrestationServicesById(contractor): Observable<any> {
    console.log('fetching all prestation contractor by id...')
    return this.http.get(`${this.baseUrl}getPrestation?prestataire=${contractor.prestataire}`).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount(), // used to keep the observable alive for as long as there are subscribers.
    )
  }


   /**
   * Return all prestations for a contractor by id services (You can call this method whitout token)
   * @param contractor
   * @returns {Observable<any>}
   */
    getPrestationServicesByIdPagniation(contractor,limit,token): Observable<any> {
      console.log('fetching all prestation contractor by id...')

      if(token){
        let headers = { 'Jwt': token };
      
        return this.http.get(`${this.baseUrl}getPrestation?prestataire=${contractor.prestataire}&limit=${limit}`,{ headers: headers }).pipe(
          map(res => res),
          catchError(this.handleError),
          publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
          refCount(), // used to keep the observable alive for as long as there are subscribers.
        )
      }
      return this.http.get(`${this.baseUrl}getPrestation?prestataire=${contractor.prestataire}&limit=${limit}`).pipe(
        map(res => res),
        catchError(this.handleError),
        publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
        refCount(), // used to keep the observable alive for as long as there are subscribers.
      )
    }


  /**
   * Filter prestation services via le token
   * @returns {Observable<R|T>}
   */
  filterPrestationServices(filter: any,token): Observable<any> {


    if(token){
      let headers = { 'Jwt': token };
    
    return this.http.get(`${this.baseUrl}getPrestation${filter}`,{ headers: headers }).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount(), // used to keep the observable alive for as long as there are subscribers.
    )
    }

    return this.http.get(`${this.baseUrl}getPrestation${filter}`).pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount(), // used to keep the observable alive for as long as there are subscribers.
    )
  }

  /**
   * Paid order via API
   * @param order
   * @param token
   * @returns {Observable<R|T>}
   */
  paidOrder(order: Object, token): Observable<any> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}getAutorisationPaiement`, this.serialize(order), {headers: headers})
    .pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount(), // used to keep the observable alive for as long as there are subscribers.
    )
  }

  /**
   * Paid order via API
   * @param order
   * @param token
   * @returns {Observable<R|T>}
   */
  cancelPaidOrder(order: Object, token): Observable<any> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}cancelsReservation`, this.serialize(order), {headers: headers})
    .pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount(), // used to keep the observable alive for as long as there are subscribers.
    )
  }

  /**
   * Note contractor via API
   * @param rating
   * @param token
   * @returns {Observable<any>}
   */
  noteContractor(rating: any, token): Observable<any> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}noteProvider`, this.serialize(rating), {headers: headers})
    .pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount(), // used to keep the observable alive for as long as there are subscribers.
    )
  }

  /**
   * Reserve service from client via API
   * @param service
   * @param token
   * @returns {Observable<R|T>}
   */
  reserveService(service: Object, token): Observable<any> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}reserve`, this.serialize(service), {headers: headers})
    .pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount(), // used to keep the observable alive for as long as there are subscribers.
    )
  }

  /**
   * Retrieve client calendar services via le token
   * @param service
   * @param token
   * @returns {Observable<any>}
   */
  getClientCalendarService(service: any, token: any): Observable<any> {
    let headers = {'Jwt': token};
    let fields = `?id_prestataire=${service.id_prestataire}`;

    return this.http.get(`${this.baseUrl}getCalenderProvider${fields}`, {headers: headers}).pipe(
      map(res => res),
      catchError(this.handleError)
    )
  }

  /**
   * Retrieve notice for contractor via le token
   * @param notice
   * @param token
   * @returns {Observable<any>}
   */
  getNoticeService(notice: any, token: any): Observable<any> {
  
    return this.http.get(this.baseUrl + `getAvis?id_prestataire=${notice.id_prestataire}&limit=${notice.limit}&offset=${notice.offset}`).pipe(
      map(res => res),
      catchError(this.handleError)
    )
  }

  /**
   * Reserve benefits via API
   * @param book
   * @param token
   * @returns {Observable<R|T>}
   */
  bookBenefits(book: Object, token): Observable<any> {
    let headers = {'Jwt': token, 'Content-type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${this.baseUrl}reserve`, this.serialize(book), {headers: headers})
    .pipe(
      map(res => res),
      catchError(this.handleError),
      publishReplay(1), // tells rxjs to cache the most recent value which is perfect for single value http calls.
      refCount(), // used to keep the observable alive for as long as there are subscribers.
    )
  }

  /**
   * Reload data even if it is cached
   * Load item from cache if it's in cache or load from origin observable
   */
  refreshCache() {
    this._userOrders = null;
  }

  /**
   * Invalidate for a specific group key
   */
  invalidateCache() {
    this.cache.clearGroup(this.anyKey);
    this._userOrders = null;
  }


  /**
   * Retourne l'url de l'api
   * @returns url
   */
  protected getEntityUrl(params) {
    return this.baseUrl + this.getEntityName()+`${params}`;
  }

}
