import { Component } from '@angular/core';
import { ShopServicesProvider } from "../../providers/shop-services/shop-services";
import { OrderUserProvider } from "../../providers/order-user/order-user";
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FeedsReservationPage } from 'src/app/pages/feeds-reservation/feeds-reservation';


/**
 * Generated class for the CategoryUserComponent component.
 *
 * See https:
 * Components.
 */
@Component({
  selector: 'category-user',
  templateUrl: 'category-user.html',
  styleUrls: ['./category-user.scss']
})
export class CategoryUserComponent {

  category: any = [];
  loading: any;
  isBusy: boolean;
  showSplash = true;
  dataOrder;
  categories = [
    {
      nom_categorie: 'Coiffure',
      urlService: 'assets/img/services/1.jpg',
      sort: 1
    },
    {
      nom_categorie: 'Make Up',
      urlService: 'assets/img/services/2.jpg',
      sort: 2
    },
    {
      nom_categorie: 'Cils',
      urlService: 'assets/img/services/3.jpg',
      sort: 3
    },
    {
      nom_categorie: 'Ongles',
      urlService: 'assets/img/services/4.jpg',
      sort: 4
    },
    {
      nom_categorie: 'Esthétique',
      urlService: 'assets/img/services/5.jpg',
      sort: 5
    },
    {
      nom_categorie: 'Evénement',
      urlService: 'assets/img/services/6.jpg',
      sort: 6
    }
  ]
  constructor(private shop: ShopServicesProvider,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    public modalCtrl: ModalController,
    public router: Router,
    private noticeProvider: OrderUserProvider,
    private navCtrl: NavController) {

    this.isBusy = true;

  }


  public loadingEnd(m) {
    if (m) {
      m.isLoaded = true;
    }
  }



  setMyStyles(picture_url) {

    return {
      'background-image': `url('${picture_url}')`,
      'background-repeat': 'no-repeat',
      'height': '123px ',
      'background-size': 'cover'
    }
  }

  /**
  *
  * @param order
  * @param {boolean} historic
  */
  async detailReservation() {
    this.dataOrder["ETAT_COMMANDE_VALUE"] = "3";
    localStorage.setItem("order", JSON.stringify(this.dataOrder));
    const modal = await this.modalCtrl.create({
      component: FeedsReservationPage,
      cssClass: 'transparent-modal',
      componentProps: {
        order: this.dataOrder,
        modal: true,
        historic: true, from: true

      }
    });
    modal.present();

  }

  ngOnInit() {
    this.getServices();
  }

  /**
   * Retrieve all services - categories (SoulnBeauty)
   */
  getServices() {
    this.shop.getCategories(false).subscribe((category) => {
      category.result.map((data, index) => {
        if (data.nom_categorie === 'Coiffure') {
          this.categories[0]["id_categorie"] = data.id_categorie;
        } else if (data.nom_categorie === 'Make Up') {
          this.categories[1]["id_categorie"] = data.id_categorie;
        } else if (data.nom_categorie === 'Ongles') {
          this.categories[3]["id_categorie"] = data.id_categorie;
        } else if (data.nom_categorie === 'Esthétique') {
          this.categories[4]["id_categorie"] = data.id_categorie;
        } else if (data.nom_categorie === 'Evénement') {
          this.categories[5]["id_categorie"] = data.id_categorie;
        } else if (data.nom_categorie.includes('Cils')) {
          this.categories[2]["id_categorie"] = data.id_categorie;
        }
      });

    }, (err) => {

      this.toastNotification(<any>err);
    });
  }


  /**
   * Go to sub category
   * @param categoryId
   */
  goToSubCategory(categoryId) {

    // this.shop.getNoteStatus().subscribe((data) => {
    //   if (data.succes) {

    //     this.dataOrder = data.result;
    //     this.detailReservation();
    //   }
    //   else {
    //     console.log(categoryId)

    //     this.router.navigate(['subCategoryBisPage'], { queryParams: { subCategoryId: categoryId.id_categorie, categoryName: categoryId.nom_categorie } });
    //     this.dataOrder = null;
    //   }
    // }, (err) => {
    //   console.log(err);
    //   this.router.navigate(['subCategoryBisPage'], { queryParams: { subCategoryId: categoryId.id_categorie, categoryName: categoryId.nom_categorie } });
    // });
console.log(categoryId)
    this.router.navigate(['subCategoryBisPage'], { queryParams: { subCategoryId: categoryId.id_categorie, categoryName: categoryId.nom_categorie } });
    this.dataOrder = null;

  }

  /**
   * Filter by unique value
   * @param arrayList
   */
  uniqueFilter(arrayList) {
    return arrayList.filter((value, index, self) => {
      return self.map(value => value.nom_categorie).indexOf(value.nom_categorie) === index;
    });
  }

  /**
   * Cette fonction permet d'afficher le Loading...
   */
  showLoader() {
    this.loading = this.loadingCtrl.create({
      spinner: 'circles',
    });
    this.loading.present();
  }

  /**
   * Notification
   * @param msg
   * @param {string} position
   */
  async toastNotification(msg: any, position: string = 'bottom') {
    let toast = await this.toastCtrl.create({
      message: msg,
      position: 'bottom',
      cssClass: 'dark-trans',
      buttons: [
        {
          side: 'end',
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ]
    });
    await toast.present()
  }

}
