import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {FormGroup} from "@angular/forms";
import { Observable } from 'rxjs';

/*
  Generated class for the MatchPasswordValidatorProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn:'root'
})
export class MatchPasswordValidatorProvider {

  constructor() {
  }

  /**
   * Function: vérifie la confirmation mot de passe
   * @param group
   * @returns {any}
   */
  static matchPassword(group: FormGroup): Observable<any> {
    return Observable.create((observer) => {
      if (!group.parent) {
        observer.next({"password": true});
        observer.complete()
      }

      let password = group.parent.controls['password'].value;
      let confirm = group.parent.controls['confimpassword'].value;

      if ((password && confirm) && (password != confirm)) {
        observer.next({"password": true});
        observer.complete()
      } else {
        observer.next(null);
        observer.complete()
      }
    });
  }

}
